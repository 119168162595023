import React from 'react';
import { Accordion } from 'react-bootstrap';
import './Accordion.scss';

type Tab = {
  title: string;
  content: React.ReactNode;
};

type Props = {
  title: string;
  children?: React.ReactNode;
  tabs?: Tab[];
};

const AccordionTab = (props: Props) => {
  const { title, children, tabs } = props;
  return (
      <Accordion defaultActiveKey="1" id="action-header" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header >{title}</Accordion.Header>
          <Accordion.Body>
            {!tabs && children}
            {tabs && 'TODO: Render tabs based on the array (use another component)'}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
  );
};

export default AccordionTab;
