import {
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_RESPONSE,
  POST_ROLE_FAILURE,
  POST_ROLE_REQUEST,
  POST_ROLE_RESPONSE,
  RoleState,
  RoleActionTypes,
  FETCH_ROLE_RESPONSE,
  FETCH_ROLE_FAILURE,
  FETCH_ROLE_REQUEST,
} from '../types/Role';
import { parseValidationErrors } from '../helpers/functions';

const initialState: RoleState = {
  roles: null,
  activeRole: null,
  isFetchingRoles: false,
  fetchingRolesFailed: false,
  isPostingRole: false,
  postingRoleFailed: false,
  postingRoleError: null,
  postingRoleValidationErrors: null,
};

const rolesReducer = (
  state = initialState,
  action: RoleActionTypes,
): RoleState => {
  switch (action.type) {
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        roles: null,
        isFetchingRoles: true,
        fetchingRolesFailed: false,
      };
    case FETCH_ROLES_RESPONSE:
      return {
        ...state,
        roles: action.payload || [],
        isFetchingRoles: false,
        fetchingRolesFailed: false,
      };
    case FETCH_ROLES_FAILURE:
      return {
        ...state,
        roles: null,
        isFetchingRoles: false,
        fetchingRolesFailed: true,
      };
    case FETCH_ROLE_REQUEST:
      return {
        ...state,
        roles: null,
        isFetchingRoles: true,
        fetchingRolesFailed: false,
      };
    case FETCH_ROLE_RESPONSE:
      return {
        ...state,
        activeRole: action.payload,
        isFetchingRoles: false,
        fetchingRolesFailed: false,
      };
    case FETCH_ROLE_FAILURE:
      return {
        ...state,
        roles: null,
        isFetchingRoles: false,
        fetchingRolesFailed: true,
      };
    case POST_ROLE_REQUEST:
      return {
        ...state,
        isPostingRole: true,
        postingRoleError: null,
        postingRoleValidationErrors: null,
        postingRoleFailed: false,
      };
    case POST_ROLE_RESPONSE:
      return {
        ...state,
        isPostingRole: false,
        postingRoleError: null,
        postingRoleValidationErrors: null,
        postingRoleFailed: false,
      };
    case POST_ROLE_FAILURE:
      return {
        ...state,
        isPostingRole: false,
        postingRoleError: action.payload.error || 'An error occurred',
        postingRoleValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingRoleFailed: true,
      };
    default:
      return state;
  }
};

export default rolesReducer;
