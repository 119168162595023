import { ApiDataModel, FormError } from './Common';

export const FETCH_ENTRY_EDGES_REQUEST = 'FETCH_ENTRY_EDGES_REQUEST';
export const FETCH_ENTRY_EDGES_RESPONSE = 'FETCH_ENTRY_EDGES_RESPONSE';
export const FETCH_ENTRY_EDGES_FAILURE = 'FETCH_ENTRY_EDGES_FAILURE';

export const FETCH_ENTRY_EDGE_REQUEST = 'FETCH_ENTRY_EDGE_REQUEST';
export const FETCH_ENTRY_EDGE_RESPONSE = 'FETCH_ENTRY_EDGE_RESPONSE';
export const FETCH_ENTRY_EDGE_FAILURE = 'FETCH_ENTRY_EDGE_FAILURE';

export const POST_ENTRY_EDGE_REQUEST = 'POST_ENTRY_EDGE_REQUEST';
export const POST_ENTRY_EDGE_RESPONSE = 'POST_ENTRY_EDGE_RESPONSE';
export const POST_ENTRY_EDGE_FAILURE = 'POST_ENTRY_EDGE_FAILURE';

export interface EntryEdge {
  id: string;
  groupId: string;
  clientId: string;
  fromEntryId: string;
  fromEntryTitle: string;
  fromEntryDescription: string;
  fromEntryAssetId: string
  toEntryId: string;
  toEntryTitle: string;
  toEntryDescription: string;
  toEntryAssetId: string
  active: boolean;
  created: Date;
  createdBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface Location {
  childLocation: string,
  parentLocation: string,
  created: string,
  fromEntryId: string,
  toEntryId: string,
}

export interface EntryEdgeDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryEdge[];
  locations: Location[];
}

export interface EntryEdgeState {
  entryEdges: EntryEdgeDataModel | null,
  activeEntryEdge: EntryEdge | null,
  isFetchingEntryEdges: boolean;
  fetchingEntryEdgesFailed: boolean;
  postingEntryEdgeFailed?: boolean;
  isPostingEntryEdge: boolean;
  postingEntryEdgeValidationErrors: FormError[] | null;
  postingEntryEdgeError: string | null;
}

interface FetchEntryEdgesRequestAction {
  type: typeof FETCH_ENTRY_EDGES_REQUEST;
  payload: null;
}

interface FetchEntryEdgesResponseAction {
  type: typeof FETCH_ENTRY_EDGES_RESPONSE;
  payload: EntryEdgeDataModel;
}

interface FetchEntryEdgesFailureAction {
  type: typeof FETCH_ENTRY_EDGES_FAILURE;
  payload: null;
}

interface FetchEntryEdgeRequestAction {
  type: typeof FETCH_ENTRY_EDGE_REQUEST;
  payload: null;
}

interface FetchEntryEdgeResponseAction {
  type: typeof FETCH_ENTRY_EDGE_RESPONSE;
  payload: EntryEdge;
}

interface FetchEntryEdgeFailureAction {
  type: typeof FETCH_ENTRY_EDGE_FAILURE;
  payload: null;
}

interface PostEntryEdgeRequestAction {
  type: typeof POST_ENTRY_EDGE_REQUEST;
  payload: null;
}

interface PostEntryEdgeResponseAction {
  type: typeof POST_ENTRY_EDGE_RESPONSE;
  payload: null;
}

interface PostEntryEdgeFailureAction {
  type: typeof POST_ENTRY_EDGE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type EntryEdgeActionTypes =
    | FetchEntryEdgesRequestAction
    | FetchEntryEdgesResponseAction
    | FetchEntryEdgesFailureAction
    | PostEntryEdgeRequestAction
    | PostEntryEdgeResponseAction
    | PostEntryEdgeFailureAction
    | FetchEntryEdgeRequestAction
    | FetchEntryEdgeResponseAction
    | FetchEntryEdgeFailureAction;
