import { ApiDataModel, FormError } from './Common';

export const FETCH_BADGES_REQUEST = 'FETCH_BADGES_REQUEST';
export const FETCH_BADGES_RESPONSE = 'FETCH_BADGES_RESPONSE';
export const FETCH_BADGES_FAILURE = 'FETCH_BADGES_FAILURE';

export const FETCH_BADGE_REQUEST = 'FETCH_BADGE_REQUEST';
export const FETCH_BADGE_RESPONSE = 'FETCH_BADGE_RESPONSE';
export const FETCH_BADGE_FAILURE = 'FETCH_BADGE_FAILURE';

export const POST_BADGE_REQUEST = 'POST_BADGE_REQUEST';
export const POST_BADGE_RESPONSE = 'POST_BADGE_RESPONSE';
export const POST_BADGE_FAILURE = 'POST_BADGE_FAILURE';

export interface Badge {
  id: string;
  value: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface BadgeDataModel extends Omit<ApiDataModel, 'data'> {
  data: Badge[];
}

export interface BadgeState {
  badges: BadgeDataModel | null,
  activeBadge: Badge | null,
  isFetchingBadges: boolean;
  fetchingBadgesFailed: boolean;
  postingBadgeFailed?: boolean;
  isPostingBadge: boolean;
  postingBadgeValidationErrors: FormError[] | null;
  postingBadgeError: string | null;
}

interface FetchBadgesRequestAction {
  type: typeof FETCH_BADGES_REQUEST;
  payload: null;
}

interface FetchBadgesResponseAction {
  type: typeof FETCH_BADGES_RESPONSE;
  payload: BadgeDataModel;
}

interface FetchBadgesFailureAction {
  type: typeof FETCH_BADGES_FAILURE;
  payload: null;
}

interface FetchBadgeRequestAction {
  type: typeof FETCH_BADGE_REQUEST;
  payload: null;
}

interface FetchBadgeResponseAction {
  type: typeof FETCH_BADGE_RESPONSE;
  payload: Badge;
}

interface FetchBadgeFailureAction {
  type: typeof FETCH_BADGE_FAILURE;
  payload: null;
}

interface PostBadgeRequestAction {
  type: typeof POST_BADGE_REQUEST;
  payload: null;
}

interface PostBadgeResponseAction {
  type: typeof POST_BADGE_RESPONSE;
  payload: null;
}

interface PostBadgeFailureAction {
  type: typeof POST_BADGE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type BadgeActionTypes =
    | FetchBadgesRequestAction
    | FetchBadgesResponseAction
    | FetchBadgesFailureAction
    | PostBadgeRequestAction
    | PostBadgeResponseAction
    | PostBadgeFailureAction
    | FetchBadgeRequestAction
    | FetchBadgeResponseAction
    | FetchBadgeFailureAction;
