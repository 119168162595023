import {
  FETCH_ENTRY_EDGE_FAILURE,
  FETCH_ENTRY_EDGE_REQUEST,
  FETCH_ENTRY_EDGE_RESPONSE,
  FETCH_ENTRY_EDGES_FAILURE,
  FETCH_ENTRY_EDGES_REQUEST,
  FETCH_ENTRY_EDGES_RESPONSE,
  POST_ENTRY_EDGE_FAILURE,
  POST_ENTRY_EDGE_RESPONSE,
  EntryEdgeActionTypes,
  EntryEdgeState,
  POST_ENTRY_EDGE_REQUEST,
} from '../types/EntryEdge';
import { parseValidationErrors } from '../helpers/functions';

const initialState: EntryEdgeState = {
  entryEdges: null,
  activeEntryEdge: null,
  isFetchingEntryEdges: false,
  fetchingEntryEdgesFailed: false,
  isPostingEntryEdge: false,
  postingEntryEdgeFailed: false,
  postingEntryEdgeError: null,
  postingEntryEdgeValidationErrors: null,
};

const entryEdgesReducer = (
  state = initialState,
  action: EntryEdgeActionTypes,
): EntryEdgeState => {
  switch (action.type) {
    case FETCH_ENTRY_EDGES_REQUEST:
      return {
        ...state,
        entryEdges: null,
        isFetchingEntryEdges: true,
        fetchingEntryEdgesFailed: false,
      };
    case FETCH_ENTRY_EDGES_RESPONSE:
      return {
        ...state,
        entryEdges: action.payload || [],
        isFetchingEntryEdges: false,
        fetchingEntryEdgesFailed: false,
      };
    case FETCH_ENTRY_EDGES_FAILURE:
      return {
        ...state,
        entryEdges: null,
        isFetchingEntryEdges: false,
        fetchingEntryEdgesFailed: true,
      };
    case FETCH_ENTRY_EDGE_REQUEST:
      return {
        ...state,
        entryEdges: null,
        isFetchingEntryEdges: true,
        fetchingEntryEdgesFailed: false,
      };
    case FETCH_ENTRY_EDGE_RESPONSE:
      return {
        ...state,
        activeEntryEdge: action.payload,
        isFetchingEntryEdges: false,
        fetchingEntryEdgesFailed: false,
      };
    case FETCH_ENTRY_EDGE_FAILURE:
      return {
        ...state,
        entryEdges: null,
        isFetchingEntryEdges: false,
        fetchingEntryEdgesFailed: true,
      };
    case POST_ENTRY_EDGE_REQUEST:
      return {
        ...state,
        isPostingEntryEdge: true,
        postingEntryEdgeError: null,
        postingEntryEdgeValidationErrors: null,
        postingEntryEdgeFailed: false,
      };
    case POST_ENTRY_EDGE_RESPONSE:
      return {
        ...state,
        isPostingEntryEdge: false,
        postingEntryEdgeError: null,
        postingEntryEdgeValidationErrors: null,
        postingEntryEdgeFailed: false,
      };
    case POST_ENTRY_EDGE_FAILURE:
      return {
        ...state,
        isPostingEntryEdge: false,
        postingEntryEdgeError: action.payload.error || 'An error occurred',
        postingEntryEdgeValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingEntryEdgeFailed: true,
      };
    default:
      return state;
  }
};

export default entryEdgesReducer;
