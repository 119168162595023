import axios from 'axios';

import c from '../helpers/constants';

export function getEntryHistory(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_HISTORY}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryTimeline(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TIMELINE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryTimelineByShortcodes(groupShortcode: string, clientShortcode: string, id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TIMELINE}/${groupShortcode}/${clientShortcode}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getEntryHistoryByShortcodes(groupShortcode: string, clientShortcode: string, id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_HISTORY}/${groupShortcode}/${clientShortcode}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getChildLocationHistory(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CHILD_LOCATION_TIMELINE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getParentLocationHistory(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.PARENT_LOCATION_TIMELINE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getEntriesHistory(searchField?: string, searchWord?: string, colKey?: string, sortDirection?: string,  paging?: string, offset?: number) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_HISTORY,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        searchField,
        searchWord,
        colKey,
        sortDirection,
        paging,
        offset,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

/*export function postEntryHistory(groupId: string, clientId: string, title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_HISTORY,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryHistory(
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string | null,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_HISTORY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveEntryHistory(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_HISTORY}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}*/

