import {
  FETCH_FORM_FIELD_FAILURE,
  FETCH_FORM_FIELD_REQUEST,
  FETCH_FORM_FIELD_RESPONSE,
  FETCH_FORM_FIELDS_FAILURE,
  FETCH_FORM_FIELDS_REQUEST,
  FETCH_FORM_FIELDS_RESPONSE,
  POST_FORM_FIELD_FAILURE,
  POST_FORM_FIELD_RESPONSE,
  FormFieldActionTypes,
  FormFieldState,
  POST_FORM_FIELD_REQUEST,
} from '../types/FormField';
import { parseValidationErrors } from '../helpers/functions';

const initialState: FormFieldState = {
  formFields: null,
  activeFormField: null,
  isFetchingFormFields: false,
  fetchingFormFieldsFailed: false,
  isPostingFormField: false,
  postingFormFieldFailed: false,
  postingFormFieldError: null,
  postingFormFieldValidationErrors: null,
};

const formFieldsReducer = (
  state = initialState,
  action: FormFieldActionTypes,
): FormFieldState => {
  switch (action.type) {
    case FETCH_FORM_FIELDS_REQUEST:
      return {
        ...state,
        formFields: null,
        isFetchingFormFields: true,
        fetchingFormFieldsFailed: false,
      };
    case FETCH_FORM_FIELDS_RESPONSE:
      return {
        ...state,
        formFields: action.payload || [],
        isFetchingFormFields: false,
        fetchingFormFieldsFailed: false,
      };
    case FETCH_FORM_FIELDS_FAILURE:
      return {
        ...state,
        formFields: null,
        isFetchingFormFields: false,
        fetchingFormFieldsFailed: true,
      };
    case FETCH_FORM_FIELD_REQUEST:
      return {
        ...state,
        formFields: null,
        isFetchingFormFields: true,
        fetchingFormFieldsFailed: false,
      };
    case FETCH_FORM_FIELD_RESPONSE:
      return {
        ...state,
        activeFormField: action.payload,
        isFetchingFormFields: false,
        fetchingFormFieldsFailed: false,
      };
    case FETCH_FORM_FIELD_FAILURE:
      return {
        ...state,
        formFields: null,
        isFetchingFormFields: false,
        fetchingFormFieldsFailed: true,
      };
    case POST_FORM_FIELD_REQUEST:
      return {
        ...state,
        isPostingFormField: true,
        postingFormFieldError: null,
        postingFormFieldValidationErrors: null,
        postingFormFieldFailed: false,
      };
    case POST_FORM_FIELD_RESPONSE:
      return {
        ...state,
        isPostingFormField: false,
        postingFormFieldError: null,
        postingFormFieldValidationErrors: null,
        postingFormFieldFailed: false,
      };
    case POST_FORM_FIELD_FAILURE:
      return {
        ...state,
        isPostingFormField: false,
        postingFormFieldError: action.payload.error || 'An error occurred',
        postingFormFieldValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingFormFieldFailed: true,
      };
    default:
      return state;
  }
};

export default formFieldsReducer;
