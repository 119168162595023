import {
  FETCH_ENTRY_TAG_FAILURE,
  FETCH_ENTRY_TAG_REQUEST,
  FETCH_ENTRY_TAG_RESPONSE,
  FETCH_ENTRY_TAGS_FAILURE,
  FETCH_ENTRY_TAGS_REQUEST,
  FETCH_ENTRY_TAGS_RESPONSE,
  POST_ENTRY_TAG_FAILURE,
  POST_ENTRY_TAG_RESPONSE,
  EntryTagActionTypes,
  EntryTagState,
  POST_ENTRY_TAG_REQUEST,
} from '../types/EntryTag';
import { parseValidationErrors } from '../helpers/functions';

const initialState: EntryTagState = {
  entryTags: null,
  activeEntryTag: null,
  isFetchingEntryTags: false,
  fetchingEntryTagsFailed: false,
  isPostingEntryTag: false,
  postingEntryTagFailed: false,
  postingEntryTagError: null,
  postingEntryTagValidationErrors: null,
};

const entryTagsReducer = (
  state = initialState,
  action: EntryTagActionTypes,
): EntryTagState => {
  switch (action.type) {
    case FETCH_ENTRY_TAGS_REQUEST:
      return {
        ...state,
        entryTags: null,
        isFetchingEntryTags: true,
        fetchingEntryTagsFailed: false,
      };
    case FETCH_ENTRY_TAGS_RESPONSE:
      return {
        ...state,
        entryTags: action.payload || [],
        isFetchingEntryTags: false,
        fetchingEntryTagsFailed: false,
      };
    case FETCH_ENTRY_TAGS_FAILURE:
      return {
        ...state,
        entryTags: null,
        isFetchingEntryTags: false,
        fetchingEntryTagsFailed: true,
      };
    case FETCH_ENTRY_TAG_REQUEST:
      return {
        ...state,
        entryTags: null,
        isFetchingEntryTags: true,
        fetchingEntryTagsFailed: false,
      };
    case FETCH_ENTRY_TAG_RESPONSE:
      return {
        ...state,
        activeEntryTag: action.payload,
        isFetchingEntryTags: false,
        fetchingEntryTagsFailed: false,
      };
    case FETCH_ENTRY_TAG_FAILURE:
      return {
        ...state,
        entryTags: null,
        isFetchingEntryTags: false,
        fetchingEntryTagsFailed: true,
      };
    case POST_ENTRY_TAG_REQUEST:
      return {
        ...state,
        isPostingEntryTag: true,
        postingEntryTagError: null,
        postingEntryTagValidationErrors: null,
        postingEntryTagFailed: false,
      };
    case POST_ENTRY_TAG_RESPONSE:
      return {
        ...state,
        isPostingEntryTag: false,
        postingEntryTagError: null,
        postingEntryTagValidationErrors: null,
        postingEntryTagFailed: false,
      };
    case POST_ENTRY_TAG_FAILURE:
      return {
        ...state,
        isPostingEntryTag: false,
        postingEntryTagError: action.payload.error || 'An error occurred',
        postingEntryTagValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingEntryTagFailed: true,
      };
    default:
      return state;
  }
};

export default entryTagsReducer;
