import { ApiDataModel, FormError } from './Common';

export const FETCH_FORM_FIELDS_REQUEST = 'FETCH_FORM_FIELDS_REQUEST';
export const FETCH_FORM_FIELDS_RESPONSE = 'FETCH_FORM_FIELDS_RESPONSE';
export const FETCH_FORM_FIELDS_FAILURE = 'FETCH_FORM_FIELDS_FAILURE';

export const FETCH_FORM_FIELD_REQUEST = 'FETCH_FORM_FIELD_REQUEST';
export const FETCH_FORM_FIELD_RESPONSE = 'FETCH_FORM_FIELD_RESPONSE';
export const FETCH_FORM_FIELD_FAILURE = 'FETCH_FORM_FIELD_FAILURE';

export const POST_FORM_FIELD_REQUEST = 'POST_FORM_FIELD_REQUEST';
export const POST_FORM_FIELD_RESPONSE = 'POST_FORM_FIELD_RESPONSE';
export const POST_FORM_FIELD_FAILURE = 'POST_FORM_FIELD_FAILURE';

export interface FormField {
  id: string;
  groupId: string;
  groupTitle: string;
  clientId: string;
  clientTitle: string;
  formId: string;
  formTitle: string;
  fieldId: string;
  fieldTitle: string;
  fieldDescription: string;
  fieldTypeTitle: string;
  fieldTypeOptionKey: string;
  fieldTypeDescription: string;
  categoryId: string;
  categoryTitle: string;
  order: number;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface FormFieldDataModel extends Omit<ApiDataModel, 'data'> {
  data: FormField[];
}

export interface FormFieldState {
  formFields: FormFieldDataModel | null,
  activeFormField: FormField | null,
  isFetchingFormFields: boolean;
  fetchingFormFieldsFailed: boolean;
  postingFormFieldFailed?: boolean;
  isPostingFormField: boolean;
  postingFormFieldValidationErrors: FormError[] | null;
  postingFormFieldError: string | null;
}

interface FetchFormFieldsRequestAction {
  type: typeof FETCH_FORM_FIELDS_REQUEST;
  payload: null;
}

interface FetchFormFieldsResponseAction {
  type: typeof FETCH_FORM_FIELDS_RESPONSE;
  payload: FormFieldDataModel;
}

interface FetchFormFieldsFailureAction {
  type: typeof FETCH_FORM_FIELDS_FAILURE;
  payload: null;
}

interface FetchFormFieldRequestAction {
  type: typeof FETCH_FORM_FIELD_REQUEST;
  payload: null;
}

interface FetchFormFieldResponseAction {
  type: typeof FETCH_FORM_FIELD_RESPONSE;
  payload: FormField;
}

interface FetchFormFieldFailureAction {
  type: typeof FETCH_FORM_FIELD_FAILURE;
  payload: null;
}

interface PostFormFieldRequestAction {
  type: typeof POST_FORM_FIELD_REQUEST;
  payload: null;
}

interface PostFormFieldResponseAction {
  type: typeof POST_FORM_FIELD_RESPONSE;
  payload: null;
}

interface PostFormFieldFailureAction {
  type: typeof POST_FORM_FIELD_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type FormFieldActionTypes =
    | FetchFormFieldsRequestAction
    | FetchFormFieldsResponseAction
    | FetchFormFieldsFailureAction
    | PostFormFieldRequestAction
    | PostFormFieldResponseAction
    | PostFormFieldFailureAction
    | FetchFormFieldRequestAction
    | FetchFormFieldResponseAction
    | FetchFormFieldFailureAction;
