import { ApiDataModel, FormError } from './Common';

export const FETCH_ENTRIES_HISTORY_REQUEST = 'FETCH_ENTRIES_HISTORY_REQUEST';
export const FETCH_ENTRIES_HISTORY_RESPONSE = 'FETCH_ENTRIES_HISTORY_RESPONSE';
export const FETCH_ENTRIES_HISTORY_FAILURE = 'FETCH_ENTRIES_HISTORY_FAILURE';

export const FETCH_ENTRY_HISTORY_REQUEST = 'FETCH_ENTRY_HISTORY_REQUEST';
export const FETCH_ENTRY_HISTORY_RESPONSE = 'FETCH_ENTRY_HISTORY_RESPONSE';
export const FETCH_ENTRY_HISTORY_FAILURE = 'FETCH_ENTRY_HISTORY_FAILURE';

export const FETCH_ENTRY_TIMELINE_REQUEST = 'FETCH_ENTRY_TIMELINE_REQUEST';
export const FETCH_ENTRY_TIMELINE_RESPONSE = 'FETCH_ENTRY_TIMELINE_RESPONSE';
export const FETCH_ENTRY_TIMELINE_FAILURE = 'FETCH_ENTRY_TIMELINE_FAILURE';

export const POST_ENTRY_HISTORY_REQUEST = 'POST_ENTRY_HISTORY_REQUEST';
export const POST_ENTRY_HISTORY_RESPONSE = 'POST_ENTRY_HISTORY_RESPONSE';
export const POST_ENTRY_HISTORY_FAILURE = 'POST_ENTRY_HISTORY_FAILURE';

export const FETCH_ENTRY_CHILD_LOCATION_HISTORY_REQUEST = 'FETCH_ENTRY_CHILD_LOCATION_HISTORY_REQUEST';
export const FETCH_ENTRY_CHILD_LOCATION_HISTORY_RESPONSE = 'FETCH_ENTRY_CHILD_LOCATION_HISTORY_RESPONSE';
export const FETCH_ENTRY_CHILD_LOCATION_HISTORY_FAILURE = 'FETCH_ENTRY_CHILD_LOCATION_HISTORY_FAILURE';

export const FETCH_ENTRY_PARENT_LOCATION_HISTORY_REQUEST = 'FETCH_ENTRY_PARENT_LOCATION_HISTORY_REQUEST';
export const FETCH_ENTRY_PARENT_LOCATION_HISTORY_RESPONSE = 'FETCH_ENTRY_PARENT_LOCATION_HISTORY_RESPONSE';
export const FETCH_ENTRY_PARENT_LOCATION_HISTORY_FAILURE = 'FETCH_ENTRY_PARENT_LOCATION_HISTORY_FAILURE';

export interface EntryHistory {
  id: string;
  groupId: string;
  clientId: string;
  formId: string;
  entryId: string;
  assetId: string;
  title: string;
  description:string;
  created: Date;
  createdBy: Date;
  updated: Date;
}

export interface Timeline {
  before: string;
  after: string;
  title: string;
  notes: string;
  created: Date;
}

export interface ChildLocationHistory {
  childId: string;
  childTitle: string;
  locationHistory: string[];
}

export interface ParentLocationHistory {
  parentId: string;
  parentTitle: string;
  locationHistory: string[];
}

export interface EntryHistoryDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryHistory[] ;
}

export interface TimelineDataModel extends Omit<ApiDataModel, 'data'> {
  data: Timeline[];
}

export interface ChildLocationHistoryDataModel extends Omit<ApiDataModel, 'data'> {
  data: ChildLocationHistory[];
}

export interface ParentLocationHistoryDataModel extends Omit<ApiDataModel, 'data'> {
  data: ParentLocationHistory[];
}

export interface EntryHistoryState {
  entriesHistory: EntryHistoryDataModel | null,
  activeEntryHistory: EntryHistory | null,
  timeline: TimelineDataModel | null,
  childLocationHistory: ChildLocationHistoryDataModel | null,
  parentLocationHistory: ParentLocationHistoryDataModel | null,
  isFetchingEntriesHistory: boolean;
  fetchingEntriesHistoryFailed: boolean;
  postingEntryHistoryFailed?: boolean;
  isPostingEntryHistory: boolean;
  postingEntryHistoryValidationErrors: FormError[] | null;
  postingEntryHistoryError: string | null;
}

interface FetchEntriesHistoryRequestAction {
  type: typeof FETCH_ENTRIES_HISTORY_REQUEST;
  payload: null;
}

interface FetchEntriesHistoryResponseAction {
  type: typeof FETCH_ENTRIES_HISTORY_RESPONSE;
  payload: EntryHistoryDataModel;
}

interface FetchEntriesHistoryFailureAction {
  type: typeof FETCH_ENTRIES_HISTORY_FAILURE;
  payload: null;
}

interface FetchEntryHistoryRequestAction {
  type: typeof FETCH_ENTRY_HISTORY_REQUEST;
  payload: null;
}

interface FetchEntryHistoryResponseAction {
  type: typeof FETCH_ENTRY_HISTORY_RESPONSE;
  payload: EntryHistory;
}

interface FetchEntryHistoryFailureAction {
  type: typeof FETCH_ENTRY_HISTORY_FAILURE;
  payload: null;
}

//Timeline
interface FetchEntryTimelineRequestAction {
  type: typeof FETCH_ENTRY_TIMELINE_REQUEST;
  payload: null;
}

interface FetchEntryTimelineResponseAction {
  type: typeof FETCH_ENTRY_TIMELINE_RESPONSE;
  payload: TimelineDataModel;
}

interface FetchEntryTimelineFailureAction {
  type: typeof FETCH_ENTRY_TIMELINE_FAILURE;
  payload: null;
}

//Child Location Timeline
interface FetchEntryChildLocationHistoryRequestAction {
  type: typeof FETCH_ENTRY_CHILD_LOCATION_HISTORY_REQUEST;
  payload: null;
}

interface FetchEntryChildLocationHistoryResponseAction {
  type: typeof FETCH_ENTRY_CHILD_LOCATION_HISTORY_RESPONSE;
  payload: ChildLocationHistoryDataModel;
}

interface FetchEntryChildLocationHistoryFailureAction {
  type: typeof FETCH_ENTRY_CHILD_LOCATION_HISTORY_FAILURE;
  payload: null;
}

//Parent Location Timeline
interface FetchEntryParentLocationHistoryRequestAction {
  type: typeof FETCH_ENTRY_PARENT_LOCATION_HISTORY_REQUEST;
  payload: null;
}

interface FetchEntryParentLocationHistoryResponseAction {
  type: typeof FETCH_ENTRY_PARENT_LOCATION_HISTORY_RESPONSE;
  payload: ParentLocationHistoryDataModel;
}

interface FetchEntryParentLocationHistoryFailureAction {
  type: typeof FETCH_ENTRY_PARENT_LOCATION_HISTORY_FAILURE;
  payload: null;
}

/*interface PostEntryHistoryRequestAction {
  type: typeof POST_ENTRY_HISTORY_REQUEST;
  payload: null;
}

interface PostEntryHistoryResponseAction {
  type: typeof POST_ENTRY_HISTORY_RESPONSE;
  payload: null;
}

interface PostEntryHistoryFailureAction {
  type: typeof POST_ENTRY_HISTORY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}*/

export type EntryHistoryActionTypes =
    | FetchEntriesHistoryRequestAction
    | FetchEntriesHistoryResponseAction
    | FetchEntriesHistoryFailureAction
    | FetchEntryTimelineRequestAction
    | FetchEntryTimelineResponseAction
    | FetchEntryTimelineFailureAction
    | FetchEntryChildLocationHistoryRequestAction
    | FetchEntryChildLocationHistoryResponseAction
    | FetchEntryChildLocationHistoryFailureAction
    | FetchEntryParentLocationHistoryRequestAction
    | FetchEntryParentLocationHistoryResponseAction
    | FetchEntryParentLocationHistoryFailureAction
    /*    | PostEntryHistoryRequestAction
    | PostEntryHistoryResponseAction
    | PostEntryHistoryFailureAction*/
    | FetchEntryHistoryRequestAction
    | FetchEntryHistoryResponseAction
    | FetchEntryHistoryFailureAction;
