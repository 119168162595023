import { ApiDataModel, FormError } from './Common';

export const FETCH_FORMS_REQUEST = 'FETCH_FORMS_REQUEST';
export const FETCH_FORMS_RESPONSE = 'FETCH_FORMS_RESPONSE';
export const FETCH_FORMS_FAILURE = 'FETCH_FORMS_FAILURE';

export const FETCH_FORM_REQUEST = 'FETCH_FORM_REQUEST';
export const FETCH_FORM_RESPONSE = 'FETCH_FORM_RESPONSE';
export const FETCH_FORM_FAILURE = 'FETCH_FORM_FAILURE';

export const POST_FORM_REQUEST = 'POST_FORM_REQUEST';
export const POST_FORM_RESPONSE = 'POST_FORM_RESPONSE';
export const POST_FORM_FAILURE = 'POST_FORM_FAILURE';

export interface Form {
  id: string;
  groupId: string;
  clientId: string;
  viewId: string;
  title: string;
  description: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface FormDataModel extends Omit<ApiDataModel, 'data'> {
  data: Form[];
}

export interface FormState {
  forms: FormDataModel | null,
  activeForm: Form | null,
  isFetchingForms: boolean;
  fetchingFormsFailed: boolean;
  postingFormFailed?: boolean;
  isPostingForm: boolean;
  postingFormValidationErrors: FormError[] | null;
  postingFormError: string | null;
}

interface FetchFormsRequestAction {
  type: typeof FETCH_FORMS_REQUEST;
  payload: null;
}

interface FetchFormsResponseAction {
  type: typeof FETCH_FORMS_RESPONSE;
  payload: FormDataModel;
}

interface FetchFormsFailureAction {
  type: typeof FETCH_FORMS_FAILURE;
  payload: null;
}

interface FetchFormRequestAction {
  type: typeof FETCH_FORM_REQUEST;
  payload: null;
}

interface FetchFormResponseAction {
  type: typeof FETCH_FORM_RESPONSE;
  payload: Form;
}

interface FetchFormFailureAction {
  type: typeof FETCH_FORM_FAILURE;
  payload: null;
}

interface PostFormRequestAction {
  type: typeof POST_FORM_REQUEST;
  payload: null;
}

interface PostFormResponseAction {
  type: typeof POST_FORM_RESPONSE;
  payload: null;
}

interface PostFormFailureAction {
  type: typeof POST_FORM_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type FormActionTypes =
    | FetchFormsRequestAction
    | FetchFormsResponseAction
    | FetchFormsFailureAction
    | PostFormRequestAction
    | PostFormResponseAction
    | PostFormFailureAction
    | FetchFormRequestAction
    | FetchFormResponseAction
    | FetchFormFailureAction;
