import { RootState } from '../store/configureStore';

export const getEntryFieldsHistory = (state: RootState) => state?.entryFieldHistory?.entryFieldsHistory;

export const getActiveEntryFieldHistory = (state: RootState) => state?.entryFieldHistory?.activeEntryFieldHistory;

export const isFetchingEntryFieldsHistory = (state: RootState) => state?.entryFieldHistory?.isFetchingEntryFieldsHistory;

export const isPostingEntryFieldHistory = (state: RootState) => state?.entryFieldHistory?.isPostingEntryFieldHistory;
export const postingEntryFieldHistoryFailed = (state: RootState) => state?.entryFieldHistory?.postingEntryFieldHistoryFailed;
export const postingEntryFieldHistoryError = (state: RootState) => state?.entryFieldHistory?.postingEntryFieldHistoryError;
export const postingEntryFieldHistoryValidationErrors = (state: RootState) => state?.entryFieldHistory?.postingEntryFieldHistoryValidationErrors;
export const fetchingEntryFieldsHistoryFailed = (state: RootState) => state?.entryFieldHistory?.fetchingEntryFieldsHistoryFailed;

