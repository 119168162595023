import { ApiDataModel, FormError } from './Common';

export const FETCH_FIELDS_REQUEST = 'FETCH_FIELDS_REQUEST';
export const FETCH_FIELDS_RESPONSE = 'FETCH_FIELDS_RESPONSE';
export const FETCH_FIELDS_FAILURE = 'FETCH_FIELDS_FAILURE';

export const FETCH_FIELD_REQUEST = 'FETCH_FIELD_REQUEST';
export const FETCH_FIELD_RESPONSE = 'FETCH_FIELD_RESPONSE';
export const FETCH_FIELD_FAILURE = 'FETCH_FIELD_FAILURE';

export const POST_FIELD_REQUEST = 'POST_FIELD_REQUEST';
export const POST_FIELD_RESPONSE = 'POST_FIELD_RESPONSE';
export const POST_FIELD_FAILURE = 'POST_FIELD_FAILURE';

export interface Field {
  id: string;
  groupId: string;
  clientId: string;
  categoryId: string;
  fieldTypeId: string;
  title: string;
  description: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface FieldDataModel extends Omit<ApiDataModel, 'data'> {
  data: Field[];
}

export interface FieldState {
  fields: FieldDataModel | null,
  activeField: Field | null,
  isFetchingFields: boolean;
  fetchingFieldsFailed: boolean;
  postingFieldFailed?: boolean;
  isPostingField: boolean;
  postingFieldValidationErrors: FormError[] | null;
  postingFieldError: string | null;
}

interface FetchFieldsRequestAction {
  type: typeof FETCH_FIELDS_REQUEST;
  payload: null;
}

interface FetchFieldsResponseAction {
  type: typeof FETCH_FIELDS_RESPONSE;
  payload: FieldDataModel;
}

interface FetchFieldsFailureAction {
  type: typeof FETCH_FIELDS_FAILURE;
  payload: null;
}

interface FetchFieldRequestAction {
  type: typeof FETCH_FIELD_REQUEST;
  payload: null;
}

interface FetchFieldResponseAction {
  type: typeof FETCH_FIELD_RESPONSE;
  payload: Field;
}

interface FetchFieldFailureAction {
  type: typeof FETCH_FIELD_FAILURE;
  payload: null;
}

interface PostFieldRequestAction {
  type: typeof POST_FIELD_REQUEST;
  payload: null;
}

interface PostFieldResponseAction {
  type: typeof POST_FIELD_RESPONSE;
  payload: null;
}

interface PostFieldFailureAction {
  type: typeof POST_FIELD_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type FieldActionTypes =
    | FetchFieldsRequestAction
    | FetchFieldsResponseAction
    | FetchFieldsFailureAction
    | PostFieldRequestAction
    | PostFieldResponseAction
    | PostFieldFailureAction
    | FetchFieldRequestAction
    | FetchFieldResponseAction
    | FetchFieldFailureAction;
