import { ApiDataModel, FormError } from './Common';

export const FETCH_VIEW_ROLES_REQUEST = 'FETCH_VIEW_ROLES_REQUEST';
export const FETCH_VIEW_ROLES_RESPONSE = 'FETCH_VIEW_ROLES_RESPONSE';
export const FETCH_VIEW_ROLES_FAILURE = 'FETCH_VIEW_ROLES_FAILURE';

export const FETCH_VIEW_ROLE_REQUEST = 'FETCH_VIEW_ROLE_REQUEST';
export const FETCH_VIEW_ROLE_RESPONSE = 'FETCH_VIEW_ROLE_RESPONSE';
export const FETCH_VIEW_ROLE_FAILURE = 'FETCH_VIEW_ROLE_FAILURE';

export const POST_VIEW_ROLE_REQUEST = 'POST_VIEW_ROLE_REQUEST';
export const POST_VIEW_ROLE_RESPONSE = 'POST_VIEW_ROLE_RESPONSE';
export const POST_VIEW_ROLE_FAILURE = 'POST_VIEW_ROLE_FAILURE';

export interface ViewRole {
  id: string;
  roleId: string;
  viewId: string;
}

export interface ViewRoleDataModel extends Omit<ApiDataModel, 'data'> {
  data: ViewRole[];
}

export interface ViewRoleState {
  viewRoles: ViewRoleDataModel | null,
  activeViewRole: ViewRole | null,
  isFetchingViewRoles: boolean;
  fetchingViewRolesFailed: boolean;
  postingViewRoleFailed: boolean;
  isPostingViewRole: boolean;
  postingViewRoleValidationErrors: FormError[] | null;
  postingViewRoleError: string | null;
}

interface FetchViewRolesRequestAction {
  type: typeof FETCH_VIEW_ROLES_REQUEST;
  payload: null;
}

interface FetchViewRolesResponseAction {
  type: typeof FETCH_VIEW_ROLES_RESPONSE;
  payload: ViewRoleDataModel;
}

interface FetchViewRolesFailureAction {
  type: typeof FETCH_VIEW_ROLES_FAILURE;
  payload: null;
}

interface FetchViewRoleRequestAction {
  type: typeof FETCH_VIEW_ROLE_REQUEST;
  payload: null;
}

interface FetchViewRoleResponseAction {
  type: typeof FETCH_VIEW_ROLE_RESPONSE;
  payload: ViewRole;
}

interface FetchViewRoleFailureAction {
  type: typeof FETCH_VIEW_ROLE_FAILURE;
  payload: null;
}

interface PostViewRoleRequestAction {
  type: typeof POST_VIEW_ROLE_REQUEST;
  payload: null;
}

interface PostViewRoleResponseAction {
  type: typeof POST_VIEW_ROLE_RESPONSE;
  payload: null;
}

interface PostViewRoleFailureAction {
  type: typeof POST_VIEW_ROLE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ViewRoleActionTypes =
    | FetchViewRolesRequestAction
    | FetchViewRolesResponseAction
    | FetchViewRolesFailureAction
    | PostViewRoleRequestAction
    | PostViewRoleResponseAction
    | PostViewRoleFailureAction
    | FetchViewRoleRequestAction
    | FetchViewRoleResponseAction
    | FetchViewRoleFailureAction;
