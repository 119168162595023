import { ApiDataModel, FormError } from './Common';

export const FETCH_SOLUTION_FUNCTIONS_REQUEST = 'FETCH_SOLUTION_FUNCTIONS_REQUEST';
export const FETCH_SOLUTION_FUNCTIONS_RESPONSE = 'FETCH_SOLUTION_FUNCTIONS_RESPONSE';
export const FETCH_SOLUTION_FUNCTIONS_FAILURE = 'FETCH_SOLUTION_FUNCTIONS_FAILURE';

export const FETCH_SOLUTION_FUNCTION_REQUEST = 'FETCH_SOLUTION_FUNCTION_REQUEST';
export const FETCH_SOLUTION_FUNCTION_RESPONSE = 'FETCH_SOLUTION_FUNCTION_RESPONSE';
export const FETCH_SOLUTION_FUNCTION_FAILURE = 'FETCH_SOLUTION_FUNCTION_FAILURE';

export const POST_SOLUTION_FUNCTION_REQUEST = 'POST_SOLUTION_FUNCTION_REQUEST';
export const POST_SOLUTION_FUNCTION_RESPONSE = 'POST_SOLUTION_FUNCTION_RESPONSE';
export const POST_SOLUTION_FUNCTION_FAILURE = 'POST_SOLUTION_FUNCTION_FAILURE';

export interface SolutionFunction {
  id: string;
  functionId: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface SolutionFunctionDataModel extends Omit<ApiDataModel, 'data'> {
  data: SolutionFunction[];
}

export interface SolutionFunctionState {
  solutionFunctions: SolutionFunctionDataModel | null,
  activeSolutionFunction: SolutionFunction | null,
  isFetchingSolutionFunctions: boolean;
  fetchingSolutionFunctionsFailed: boolean;
  postingSolutionFunctionFailed?: boolean;
  isPostingSolutionFunction: boolean;
  postingSolutionFunctionValidationErrors: FormError[] | null;
  postingSolutionFunctionError: string | null;
}

interface FetchSolutionFunctionsRequestAction {
  type: typeof FETCH_SOLUTION_FUNCTIONS_REQUEST;
  payload: null;
}

interface FetchSolutionFunctionsResponseAction {
  type: typeof FETCH_SOLUTION_FUNCTIONS_RESPONSE;
  payload: SolutionFunctionDataModel;
}

interface FetchSolutionFunctionsFailureAction {
  type: typeof FETCH_SOLUTION_FUNCTIONS_FAILURE;
  payload: null;
}

interface FetchSolutionFunctionRequestAction {
  type: typeof FETCH_SOLUTION_FUNCTION_REQUEST;
  payload: null;
}

interface FetchSolutionFunctionResponseAction {
  type: typeof FETCH_SOLUTION_FUNCTION_RESPONSE;
  payload: SolutionFunction;
}

interface FetchSolutionFunctionFailureAction {
  type: typeof FETCH_SOLUTION_FUNCTION_FAILURE;
  payload: null;
}

interface PostSolutionFunctionRequestAction {
  type: typeof POST_SOLUTION_FUNCTION_REQUEST;
  payload: null;
}

interface PostSolutionFunctionResponseAction {
  type: typeof POST_SOLUTION_FUNCTION_RESPONSE;
  payload: null;
}

interface PostSolutionFunctionFailureAction {
  type: typeof POST_SOLUTION_FUNCTION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type SolutionFunctionActionTypes =
    | FetchSolutionFunctionsRequestAction
    | FetchSolutionFunctionsResponseAction
    | FetchSolutionFunctionsFailureAction
    | PostSolutionFunctionRequestAction
    | PostSolutionFunctionResponseAction
    | PostSolutionFunctionFailureAction
    | FetchSolutionFunctionRequestAction
    | FetchSolutionFunctionResponseAction
    | FetchSolutionFunctionFailureAction;
