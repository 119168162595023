import { ApiDataModel, FormError } from './Common';

export const FETCH_FIELD_TYPES_REQUEST = 'FETCH_FIELD_TYPES_REQUEST';
export const FETCH_FIELD_TYPES_RESPONSE = 'FETCH_FIELD_TYPES_RESPONSE';
export const FETCH_FIELD_TYPES_FAILURE = 'FETCH_FIELD_TYPES_FAILURE';

export const FETCH_FIELD_TYPE_REQUEST = 'FETCH_FIELD_TYPE_REQUEST';
export const FETCH_FIELD_TYPE_RESPONSE = 'FETCH_FIELD_TYPE_RESPONSE';
export const FETCH_FIELD_TYPE_FAILURE = 'FETCH_FIELD_TYPE_FAILURE';

export const POST_FIELD_TYPE_REQUEST = 'POST_FIELD_TYPE_REQUEST';
export const POST_FIELD_TYPE_RESPONSE = 'POST_FIELD_TYPE_RESPONSE';
export const POST_FIELD_TYPE_FAILURE = 'POST_FIELD_TYPE_FAILURE';

export interface FieldType {
  id: string;
  title: string;
  description: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface FieldTypeDataModel extends Omit<ApiDataModel, 'data'> {
  data: FieldType[];
}

export interface FieldTypeState {
  fieldTypes: FieldTypeDataModel | null,
  activeFieldType: FieldType | null,
  isFetchingFieldTypes: boolean;
  fetchingFieldTypesFailed: boolean;
  postingFieldTypeFailed?: boolean;
  isPostingFieldType: boolean;
  postingFieldTypeValidationErrors: FormError[] | null;
  postingFieldTypeError: string | null;
}

interface FetchFieldTypesRequestAction {
  type: typeof FETCH_FIELD_TYPES_REQUEST;
  payload: null;
}

interface FetchFieldTypesResponseAction {
  type: typeof FETCH_FIELD_TYPES_RESPONSE;
  payload: FieldTypeDataModel;
}

interface FetchFieldTypesFailureAction {
  type: typeof FETCH_FIELD_TYPES_FAILURE;
  payload: null;
}

interface FetchFieldTypeRequestAction {
  type: typeof FETCH_FIELD_TYPE_REQUEST;
  payload: null;
}

interface FetchFieldTypeResponseAction {
  type: typeof FETCH_FIELD_TYPE_RESPONSE;
  payload: FieldType;
}

interface FetchFieldTypeFailureAction {
  type: typeof FETCH_FIELD_TYPE_FAILURE;
  payload: null;
}

interface PostFieldTypeRequestAction {
  type: typeof POST_FIELD_TYPE_REQUEST;
  payload: null;
}

interface PostFieldTypeResponseAction {
  type: typeof POST_FIELD_TYPE_RESPONSE;
  payload: null;
}

interface PostFieldTypeFailureAction {
  type: typeof POST_FIELD_TYPE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type FieldTypeActionTypes =
    | FetchFieldTypesRequestAction
    | FetchFieldTypesResponseAction
    | FetchFieldTypesFailureAction
    | PostFieldTypeRequestAction
    | PostFieldTypeResponseAction
    | PostFieldTypeFailureAction
    | FetchFieldTypeRequestAction
    | FetchFieldTypeResponseAction
    | FetchFieldTypeFailureAction;
