import {
  FETCH_VIEW_FAILURE,
  FETCH_VIEW_REQUEST,
  FETCH_VIEW_RESPONSE,
  FETCH_VIEWS_FAILURE,
  FETCH_VIEWS_REQUEST,
  FETCH_VIEWS_RESPONSE,
  POST_VIEW_FAILURE,
  POST_VIEW_RESPONSE,
  ViewActionTypes,
  ViewState,
  POST_VIEW_REQUEST,
} from '../types/View';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ViewState = {
  views: null,
  activeView: null,
  isFetchingViews: false,
  fetchingViewsFailed: false,
  isPostingView: false,
  postingViewFailed: false,
  postingViewError: null,
  postingViewValidationErrors: null,
};

const viewsReducer = (
  state = initialState,
  action: ViewActionTypes,
): ViewState => {
  switch (action.type) {
    case FETCH_VIEWS_REQUEST:
      return {
        ...state,
        views: null,
        isFetchingViews: true,
        fetchingViewsFailed: false,
      };
    case FETCH_VIEWS_RESPONSE:
      return {
        ...state,
        views: action.payload || [],
        isFetchingViews: false,
        fetchingViewsFailed: false,
      };
    case FETCH_VIEWS_FAILURE:
      return {
        ...state,
        views: null,
        isFetchingViews: false,
        fetchingViewsFailed: true,
      };
    case FETCH_VIEW_REQUEST:
      return {
        ...state,
        activeView: null,
        isFetchingViews: true,
        fetchingViewsFailed: false,
      };
    case FETCH_VIEW_RESPONSE:
      return {
        ...state,
        activeView: action.payload,
        isFetchingViews: false,
        fetchingViewsFailed: false,
      };
    case FETCH_VIEW_FAILURE:
      return {
        ...state,
        activeView: null,
        isFetchingViews: false,
        fetchingViewsFailed: true,
      };
    case POST_VIEW_REQUEST:
      return {
        ...state,
        isPostingView: true,
        postingViewError: null,
        postingViewValidationErrors: null,
        postingViewFailed: false,
      };
    case POST_VIEW_RESPONSE:
      return {
        ...state,
        isPostingView: false,
        postingViewError: null,
        postingViewValidationErrors: null,
        postingViewFailed: false,
      };
    case POST_VIEW_FAILURE:
      return {
        ...state,
        isPostingView: false,
        postingViewError: action.payload.error || 'An error occurred',
        postingViewValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingViewFailed: true,
      };
    default:
      return state;
  }
};

export default viewsReducer;
