import { ApiDataModel, FormError } from './Common';

export const FETCH_ENTRY_TAGS_REQUEST = 'FETCH_ENTRY_TAGS_REQUEST';
export const FETCH_ENTRY_TAGS_RESPONSE = 'FETCH_ENTRY_TAGS_RESPONSE';
export const FETCH_ENTRY_TAGS_FAILURE = 'FETCH_ENTRY_TAGS_FAILURE';

export const FETCH_ENTRY_TAG_REQUEST = 'FETCH_ENTRY_TAG_REQUEST';
export const FETCH_ENTRY_TAG_RESPONSE = 'FETCH_ENTRY_TAG_RESPONSE';
export const FETCH_ENTRY_TAG_FAILURE = 'FETCH_ENTRY_TAG_FAILURE';

export const POST_ENTRY_TAG_REQUEST = 'POST_ENTRY_TAG_REQUEST';
export const POST_ENTRY_TAG_RESPONSE = 'POST_ENTRY_TAG_RESPONSE';
export const POST_ENTRY_TAG_FAILURE = 'POST_ENTRY_TAG_FAILURE';

export interface EntryTag {
  id: string;
  groupId: string,
  clientId: string,
  entryId: string,
  tagId: string,
  tagValue: string,
  tagType: string,
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface EntryTagDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryTag[];
}

export interface EntryTagState {
  entryTags: EntryTagDataModel | null,
  activeEntryTag: EntryTag | null,
  isFetchingEntryTags: boolean;
  fetchingEntryTagsFailed: boolean;
  postingEntryTagFailed?: boolean;
  isPostingEntryTag: boolean;
  postingEntryTagValidationErrors: FormError[] | null;
  postingEntryTagError: string | null;
}

interface FetchEntryTagsRequestAction {
  type: typeof FETCH_ENTRY_TAGS_REQUEST;
  payload: null;
}

interface FetchEntryTagsResponseAction {
  type: typeof FETCH_ENTRY_TAGS_RESPONSE;
  payload: EntryTagDataModel;
}

interface FetchEntryTagsFailureAction {
  type: typeof FETCH_ENTRY_TAGS_FAILURE;
  payload: null;
}

interface FetchEntryTagRequestAction {
  type: typeof FETCH_ENTRY_TAG_REQUEST;
  payload: null;
}

interface FetchEntryTagResponseAction {
  type: typeof FETCH_ENTRY_TAG_RESPONSE;
  payload: EntryTag;
}

interface FetchEntryTagFailureAction {
  type: typeof FETCH_ENTRY_TAG_FAILURE;
  payload: null;
}

interface PostEntryTagRequestAction {
  type: typeof POST_ENTRY_TAG_REQUEST;
  payload: null;
}

interface PostEntryTagResponseAction {
  type: typeof POST_ENTRY_TAG_RESPONSE;
  payload: null;
}

interface PostEntryTagFailureAction {
  type: typeof POST_ENTRY_TAG_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type EntryTagActionTypes =
    | FetchEntryTagsRequestAction
    | FetchEntryTagsResponseAction
    | FetchEntryTagsFailureAction
    | PostEntryTagRequestAction
    | PostEntryTagResponseAction
    | PostEntryTagFailureAction
    | FetchEntryTagRequestAction
    | FetchEntryTagResponseAction
    | FetchEntryTagFailureAction;
