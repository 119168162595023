import { ApiDataModel, FormError } from './Common';

export const FETCH_FORM_FIELD_ROLES_REQUEST = 'FETCH_FORM_FIELD_ROLES_REQUEST';
export const FETCH_FORM_FIELD_ROLES_RESPONSE = 'FETCH_FORM_FIELD_ROLES_RESPONSE';
export const FETCH_FORM_FIELD_ROLES_FAILURE = 'FETCH_FORM_FIELD_ROLES_FAILURE';

export const FETCH_FORM_FIELD_ROLE_REQUEST = 'FETCH_FORM_FIELD_ROLE_REQUEST';
export const FETCH_FORM_FIELD_ROLE_RESPONSE = 'FETCH_FORM_FIELD_ROLE_RESPONSE';
export const FETCH_FORM_FIELD_ROLE_FAILURE = 'FETCH_FORM_FIELD_ROLE_FAILURE';

export const POST_FORM_FIELD_ROLE_REQUEST = 'POST_FORM_FIELD_ROLE_REQUEST';
export const POST_FORM_FIELD_ROLE_RESPONSE = 'POST_FORM_FIELD_ROLE_RESPONSE';
export const POST_FORM_FIELD_ROLE_FAILURE = 'POST_FORM_FIELD_ROLE_FAILURE';

export interface FormFieldRole {
  id: string;
  formFieldId: string;
  roleId: string;
}

export interface FormFieldRoleDataModel extends Omit<ApiDataModel, 'data'> {
  data: FormFieldRole[];
}

export interface FormFieldRoleState {
  formFieldRoles: FormFieldRoleDataModel | null,
  activeFormFieldRole: FormFieldRole | null,
  isFetchingFormFieldRoles: boolean;
  fetchingFormFieldRolesFailed: boolean;
  postingFormFieldRoleFailed: boolean;
  isPostingFormFieldRole: boolean;
  postingFormFieldRoleValidationErrors: FormError[] | null;
  postingFormFieldRoleError: string | null;
}

interface FetchFormFieldRolesRequestAction {
  type: typeof FETCH_FORM_FIELD_ROLES_REQUEST;
  payload: null;
}

interface FetchFormFieldRolesResponseAction {
  type: typeof FETCH_FORM_FIELD_ROLES_RESPONSE;
  payload: FormFieldRoleDataModel;
}

interface FetchFormFieldRolesFailureAction {
  type: typeof FETCH_FORM_FIELD_ROLES_FAILURE;
  payload: null;
}

interface FetchFormFieldRoleRequestAction {
  type: typeof FETCH_FORM_FIELD_ROLE_REQUEST;
  payload: null;
}

interface FetchFormFieldRoleResponseAction {
  type: typeof FETCH_FORM_FIELD_ROLE_RESPONSE;
  payload: FormFieldRole;
}

interface FetchFormFieldRoleFailureAction {
  type: typeof FETCH_FORM_FIELD_ROLE_FAILURE;
  payload: null;
}

interface PostFormFieldRoleRequestAction {
  type: typeof POST_FORM_FIELD_ROLE_REQUEST;
  payload: null;
}

interface PostFormFieldRoleResponseAction {
  type: typeof POST_FORM_FIELD_ROLE_RESPONSE;
  payload: null;
}

interface PostFormFieldRoleFailureAction {
  type: typeof POST_FORM_FIELD_ROLE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type FormFieldRoleActionTypes =
    | FetchFormFieldRolesRequestAction
    | FetchFormFieldRolesResponseAction
    | FetchFormFieldRolesFailureAction
    | PostFormFieldRoleRequestAction
    | PostFormFieldRoleResponseAction
    | PostFormFieldRoleFailureAction
    | FetchFormFieldRoleRequestAction
    | FetchFormFieldRoleResponseAction
    | FetchFormFieldRoleFailureAction;
