import {
  FETCH_TAG_FAILURE,
  FETCH_TAG_REQUEST,
  FETCH_TAG_RESPONSE,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_RESPONSE,
  POST_TAG_FAILURE,
  POST_TAG_RESPONSE,
  TagActionTypes,
  TagState,
  POST_TAG_REQUEST,
} from '../types/Tag';
import { parseValidationErrors } from '../helpers/functions';

const initialState: TagState = {
  tags: null,
  activeTag: null,
  isFetchingTags: false,
  fetchingTagsFailed: false,
  isPostingTag: false,
  postingTagFailed: false,
  postingTagError: null,
  postingTagValidationErrors: null,
};

const tagsReducer = (
  state = initialState,
  action: TagActionTypes,
): TagState => {
  switch (action.type) {
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        tags: null,
        isFetchingTags: true,
        fetchingTagsFailed: false,
      };
    case FETCH_TAGS_RESPONSE:
      return {
        ...state,
        tags: action.payload || [],
        isFetchingTags: false,
        fetchingTagsFailed: false,
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        tags: null,
        isFetchingTags: false,
        fetchingTagsFailed: true,
      };
    case FETCH_TAG_REQUEST:
      return {
        ...state,
        tags: null,
        isFetchingTags: true,
        fetchingTagsFailed: false,
      };
    case FETCH_TAG_RESPONSE:
      return {
        ...state,
        activeTag: action.payload,
        isFetchingTags: false,
        fetchingTagsFailed: false,
      };
    case FETCH_TAG_FAILURE:
      return {
        ...state,
        tags: null,
        isFetchingTags: false,
        fetchingTagsFailed: true,
      };
    case POST_TAG_REQUEST:
      return {
        ...state,
        isPostingTag: true,
        postingTagError: null,
        postingTagValidationErrors: null,
        postingTagFailed: false,
      };
    case POST_TAG_RESPONSE:
      return {
        ...state,
        isPostingTag: false,
        postingTagError: null,
        postingTagValidationErrors: null,
        postingTagFailed: false,
      };
    case POST_TAG_FAILURE:
      return {
        ...state,
        isPostingTag: false,
        postingTagError: action.payload.error || 'An error occurred',
        postingTagValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingTagFailed: true,
      };
    default:
      return state;
  }
};

export default tagsReducer;
