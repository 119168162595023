import {
  FETCH_ENTRY_HISTORY_FAILURE,
  FETCH_ENTRY_HISTORY_REQUEST,
  FETCH_ENTRY_HISTORY_RESPONSE,
  FETCH_ENTRY_CHILD_LOCATION_HISTORY_FAILURE,
  FETCH_ENTRY_CHILD_LOCATION_HISTORY_REQUEST,
  FETCH_ENTRY_CHILD_LOCATION_HISTORY_RESPONSE,
  FETCH_ENTRY_PARENT_LOCATION_HISTORY_FAILURE,
  FETCH_ENTRY_PARENT_LOCATION_HISTORY_REQUEST,
  FETCH_ENTRY_PARENT_LOCATION_HISTORY_RESPONSE,
  FETCH_ENTRIES_HISTORY_FAILURE,
  FETCH_ENTRIES_HISTORY_REQUEST,
  FETCH_ENTRIES_HISTORY_RESPONSE,
  /*  POST_ENTRY_HISTORY_FAILURE,
  POST_ENTRY_HISTORY_RESPONSE,*/
  EntryHistoryActionTypes,
  EntryHistoryState, FETCH_ENTRY_TIMELINE_REQUEST, FETCH_ENTRY_TIMELINE_RESPONSE, FETCH_ENTRY_TIMELINE_FAILURE,
  /*  POST_ENTRY_HISTORY_REQUEST,*/
} from '../types/EntryHistory';
/*import { parseValidationErrors } from '../helpers/functions';*/

const initialState: EntryHistoryState = {
  entriesHistory: null,
  childLocationHistory: null,
  parentLocationHistory: null,
  timeline: null,
  activeEntryHistory: null,
  isFetchingEntriesHistory: false,
  fetchingEntriesHistoryFailed: false,
  isPostingEntryHistory: false,
  postingEntryHistoryFailed: false,
  postingEntryHistoryError: null,
  postingEntryHistoryValidationErrors: null,
};

const entriesHistoryReducer = (
  state = initialState,
  action: EntryHistoryActionTypes,
): EntryHistoryState => {
  switch (action.type) {
    case FETCH_ENTRIES_HISTORY_REQUEST:
      return {
        ...state,
        entriesHistory: null,
        isFetchingEntriesHistory: true,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRIES_HISTORY_RESPONSE:
      return {
        ...state,
        entriesHistory: action.payload || [],
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRIES_HISTORY_FAILURE:
      return {
        ...state,
        entriesHistory: null,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: true,
      };
    case FETCH_ENTRY_HISTORY_REQUEST:
      return {
        ...state,
        entriesHistory: null,
        isFetchingEntriesHistory: true,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRY_TIMELINE_REQUEST:
      return {
        ...state,
        timeline: null,
        isFetchingEntriesHistory: true,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRY_HISTORY_RESPONSE:
      return {
        ...state,
        activeEntryHistory: action.payload,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRY_TIMELINE_RESPONSE:
      return {
        ...state,
        timeline: action.payload || [],
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: false,
      };

    case FETCH_ENTRY_TIMELINE_FAILURE:
      return {
        ...state,
        timeline: null,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: true,
      };
    case FETCH_ENTRY_HISTORY_FAILURE:
      return {
        ...state,
        entriesHistory: null,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: true,
      };

    case FETCH_ENTRY_CHILD_LOCATION_HISTORY_RESPONSE:
      return {
        ...state,
        childLocationHistory: action.payload,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRY_CHILD_LOCATION_HISTORY_REQUEST:
      return {
        ...state,
        childLocationHistory: null,
        isFetchingEntriesHistory: true,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRY_CHILD_LOCATION_HISTORY_FAILURE:
      return {
        ...state,
        childLocationHistory: null,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: true,
      };

    case FETCH_ENTRY_PARENT_LOCATION_HISTORY_RESPONSE:
      return {
        ...state,
        parentLocationHistory: action.payload,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRY_PARENT_LOCATION_HISTORY_REQUEST:
      return {
        ...state,
        parentLocationHistory: null,
        isFetchingEntriesHistory: true,
        fetchingEntriesHistoryFailed: false,
      };
    case FETCH_ENTRY_PARENT_LOCATION_HISTORY_FAILURE:
      return {
        ...state,
        parentLocationHistory: null,
        isFetchingEntriesHistory: false,
        fetchingEntriesHistoryFailed: true,
      };
      /*    case POST_ENTRY_HISTORY_REQUEST:
return {
  ...state,
  isPostingEntryHistory: true,
  postingEntryHistoryError: null,
  postingEntryHistoryValidationErrors: null,
  postingEntryHistoryFailed: false,
};
case POST_ENTRY_HISTORY_RESPONSE:
return {
  ...state,
  isPostingEntryHistory: false,
  postingEntryHistoryError: null,
  postingEntryHistoryValidationErrors: null,
  postingEntryHistoryFailed: false,
};
case POST_ENTRY_HISTORY_FAILURE:
return {
  ...state,
  isPostingEntryHistory: false,
  postingEntryHistoryError: action.payload.error || 'An error occurred',
  postingEntryHistoryValidationErrors: parseValidationErrors(action.payload.validationErrors),
  postingEntryHistoryFailed: true,
};*/
      /*    case POST_ENTRY_HISTORY_REQUEST:
      return {
        ...state,
        isPostingEntryHistory: true,
        postingEntryHistoryError: null,
        postingEntryHistoryValidationErrors: null,
        postingEntryHistoryFailed: false,
      };
    case POST_ENTRY_HISTORY_RESPONSE:
      return {
        ...state,
        isPostingEntryHistory: false,
        postingEntryHistoryError: null,
        postingEntryHistoryValidationErrors: null,
        postingEntryHistoryFailed: false,
      };
    case POST_ENTRY_HISTORY_FAILURE:
      return {
        ...state,
        isPostingEntryHistory: false,
        postingEntryHistoryError: action.payload.error || 'An error occurred',
        postingEntryHistoryValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingEntryHistoryFailed: true,
      };*/
    default:
      return state;
  }
};

export default entriesHistoryReducer;
