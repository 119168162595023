import React  from 'react';
import Themes from '../Themes/Themes';
import './Spinner.scss';
import Router from '../Router';


function App() {
    

  return (
      <div className="App">
        <Themes/>
        <Router/>
      </div>
  );
}

export default App;
