import {
  FETCH_SOLUTION_FAILURE,
  FETCH_SOLUTION_REQUEST,
  FETCH_SOLUTION_RESPONSE,
  FETCH_SOLUTIONS_FAILURE,
  FETCH_SOLUTIONS_REQUEST,
  FETCH_SOLUTIONS_RESPONSE,
  POST_SOLUTION_FAILURE,
  POST_SOLUTION_RESPONSE,
  SolutionActionTypes,
  SolutionState,
  POST_SOLUTION_REQUEST,
} from '../types/Solution';
import { parseValidationErrors } from '../helpers/functions';

const initialState: SolutionState = {
  solutions: null,
  activeSolution: null,
  isFetchingSolutions: false,
  fetchingSolutionsFailed: false,
  isPostingSolution: false,
  postingSolutionFailed: false,
  postingSolutionError: null,
  postingSolutionValidationErrors: null,
};

const solutionsReducer = (
  state = initialState,
  action: SolutionActionTypes,
): SolutionState => {
  switch (action.type) {
    case FETCH_SOLUTIONS_REQUEST:
      return {
        ...state,
        solutions: null,
        isFetchingSolutions: true,
        fetchingSolutionsFailed: false,
      };
    case FETCH_SOLUTIONS_RESPONSE:
      return {
        ...state,
        solutions: action.payload || [],
        isFetchingSolutions: false,
        fetchingSolutionsFailed: false,
      };
    case FETCH_SOLUTIONS_FAILURE:
      return {
        ...state,
        solutions: null,
        isFetchingSolutions: false,
        fetchingSolutionsFailed: true,
      };
    case FETCH_SOLUTION_REQUEST:
      return {
        ...state,
        solutions: null,
        isFetchingSolutions: true,
        fetchingSolutionsFailed: false,
      };
    case FETCH_SOLUTION_RESPONSE:
      return {
        ...state,
        activeSolution: action.payload,
        isFetchingSolutions: false,
        fetchingSolutionsFailed: false,
      };
    case FETCH_SOLUTION_FAILURE:
      return {
        ...state,
        solutions: null,
        isFetchingSolutions: false,
        fetchingSolutionsFailed: true,
      };
    case POST_SOLUTION_REQUEST:
      return {
        ...state,
        isPostingSolution: true,
        postingSolutionError: null,
        postingSolutionValidationErrors: null,
        postingSolutionFailed: false,
      };
    case POST_SOLUTION_RESPONSE:
      return {
        ...state,
        isPostingSolution: false,
        postingSolutionError: null,
        postingSolutionValidationErrors: null,
        postingSolutionFailed: false,
      };
    case POST_SOLUTION_FAILURE:
      return {
        ...state,
        isPostingSolution: false,
        postingSolutionError: action.payload.error || 'An error occurred',
        postingSolutionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingSolutionFailed: true,
      };
    default:
      return state;
  }
};

export default solutionsReducer;
