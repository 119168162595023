import { ApiDataModel, FormError } from './Common';

export const FETCH_ENTRIES_REQUEST = 'FETCH_ENTRIES_REQUEST';
export const FETCH_ENTRIES_RESPONSE = 'FETCH_ENTRIES_RESPONSE';
export const FETCH_ENTRIES_FAILURE = 'FETCH_ENTRIES_FAILURE';

export const FETCH_ENTRY_REQUEST = 'FETCH_ENTRY_REQUEST';
export const FETCH_ENTRY_RESPONSE = 'FETCH_ENTRY_RESPONSE';
export const FETCH_ENTRY_MATCHES_RESPONSE = 'FETCH_ENTRY_MATCHES_RESPONSE';
export const FETCH_ENTRY_FAILURE = 'FETCH_ENTRY_FAILURE';

export const POST_ENTRY_REQUEST = 'POST_ENTRY_REQUEST';
export const POST_ENTRY_RESPONSE = 'POST_ENTRY_RESPONSE';
export const POST_ENTRY_FAILURE = 'POST_ENTRY_FAILURE';

export interface Entry {
  id: string;
  groupId: string;
  clientId: string;
  formId: string;
  assetId: string;
  title: string;
  description: string;
  active: boolean;
  status: string;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
  archived: Date;
  archivedBy: string;
}

export interface EntryMatch {
  id: string;
  solutionId: string;
  solutionTitle: string;
  solutionDescription: string;
  tagId: string;
  tagValue: string;
  entryTitle: string;
}

export interface EntryDataModel extends Omit<ApiDataModel, 'data'> {
  data: Entry[];
}

export interface EntryMatchDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryMatch[];
}

export interface EntryState {
  entries: EntryDataModel | null,
  activeEntry: Entry | null,
  matches: EntryMatchDataModel | null,
  isFetchingEntries: boolean;
  fetchingEntriesFailed: boolean;
  postingEntryFailed?: boolean;
  isPostingEntry: boolean;
  postingEntryValidationErrors: FormError[] | null;
  postingEntryError: string | null;
}

interface FetchEntriesRequestAction {
  type: typeof FETCH_ENTRIES_REQUEST;
  payload: null;
}

interface FetchEntriesResponseAction {
  type: typeof FETCH_ENTRIES_RESPONSE;
  payload: EntryDataModel;
}

interface FetchEntriesFailureAction {
  type: typeof FETCH_ENTRIES_FAILURE;
  payload: null;
}

interface FetchEntryRequestAction {
  type: typeof FETCH_ENTRY_REQUEST;
  payload: null;
}

interface FetchEntryResponseAction {
  type: typeof FETCH_ENTRY_RESPONSE;
  payload: Entry;
}

interface FetchEntryMatchesAction {
  type: typeof FETCH_ENTRY_MATCHES_RESPONSE;
  payload: EntryMatchDataModel;
}

interface FetchEntryFailureAction {
  type: typeof FETCH_ENTRY_FAILURE;
  payload: null;
}

interface PostEntryRequestAction {
  type: typeof POST_ENTRY_REQUEST;
  payload: null;
}

interface PostEntryResponseAction {
  type: typeof POST_ENTRY_RESPONSE;
  payload: null;
}

interface PostEntryFailureAction {
  type: typeof POST_ENTRY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type EntryActionTypes =
    | FetchEntriesRequestAction
    | FetchEntriesResponseAction
    | FetchEntriesFailureAction
    | PostEntryRequestAction
    | PostEntryResponseAction
    | PostEntryFailureAction
    | FetchEntryRequestAction
    | FetchEntryResponseAction
    | FetchEntryFailureAction
    | FetchEntryMatchesAction;
