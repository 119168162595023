import {
  FETCH_MATCH_FAILURE,
  FETCH_MATCH_REQUEST,
  FETCH_MATCH_RESPONSE,
  FETCH_MATCHES_FAILURE,
  FETCH_MATCHES_REQUEST,
  FETCH_MATCHES_RESPONSE,
  POST_MATCH_FAILURE,
  POST_MATCH_RESPONSE,
  MatchActionTypes,
  MatchState,
  POST_MATCH_REQUEST,
} from '../types/Match';
import { parseValidationErrors } from '../helpers/functions';

const initialState: MatchState = {
  matches: null,
  activeMatch: null,
  isFetchingMatches: false,
  fetchingMatchesFailed: false,
  isPostingMatch: false,
  postingMatchFailed: false,
  postingMatchError: null,
  postingMatchValidationErrors: null,
};

const matchesReducer = (
  state = initialState,
  action: MatchActionTypes,
): MatchState => {
  switch (action.type) {
    case FETCH_MATCHES_REQUEST:
      return {
        ...state,
        matches: null,
        isFetchingMatches: true,
        fetchingMatchesFailed: false,
      };
    case FETCH_MATCHES_RESPONSE:
      return {
        ...state,
        matches: action.payload || [],
        isFetchingMatches: false,
        fetchingMatchesFailed: false,
      };
    case FETCH_MATCHES_FAILURE:
      return {
        ...state,
        matches: null,
        isFetchingMatches: false,
        fetchingMatchesFailed: true,
      };
    case FETCH_MATCH_REQUEST:
      return {
        ...state,
        matches: null,
        isFetchingMatches: true,
        fetchingMatchesFailed: false,
      };
    case FETCH_MATCH_RESPONSE:
      return {
        ...state,
        activeMatch: action.payload,
        isFetchingMatches: false,
        fetchingMatchesFailed: false,
      };
    case FETCH_MATCH_FAILURE:
      return {
        ...state,
        matches: null,
        isFetchingMatches: false,
        fetchingMatchesFailed: true,
      };
    case POST_MATCH_REQUEST:
      return {
        ...state,
        isPostingMatch: true,
        postingMatchError: null,
        postingMatchValidationErrors: null,
        postingMatchFailed: false,
      };
    case POST_MATCH_RESPONSE:
      return {
        ...state,
        isPostingMatch: false,
        postingMatchError: null,
        postingMatchValidationErrors: null,
        postingMatchFailed: false,
      };
    case POST_MATCH_FAILURE:
      return {
        ...state,
        isPostingMatch: false,
        postingMatchError: action.payload.error || 'An error occurred',
        postingMatchValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingMatchFailed: true,
      };
    default:
      return state;
  }
};

export default matchesReducer;
