import {
  FETCH_ENTRY_FAILURE,
  FETCH_ENTRY_REQUEST,
  FETCH_ENTRY_RESPONSE,
  FETCH_ENTRY_MATCHES_RESPONSE,
  FETCH_ENTRIES_FAILURE,
  FETCH_ENTRIES_REQUEST,
  FETCH_ENTRIES_RESPONSE,
  POST_ENTRY_FAILURE,
  POST_ENTRY_RESPONSE,
  EntryActionTypes,
  EntryState,
  POST_ENTRY_REQUEST,
} from '../types/Entry';
import { parseValidationErrors } from '../helpers/functions';

const initialState: EntryState = {
  entries: null,
  matches: null,
  activeEntry: null,
  isFetchingEntries: false,
  fetchingEntriesFailed: false,
  isPostingEntry: false,
  postingEntryFailed: false,
  postingEntryError: null,
  postingEntryValidationErrors: null,
};

const entriesReducer = (
  state = initialState,
  action: EntryActionTypes,
): EntryState => {
  switch (action.type) {
    case FETCH_ENTRIES_REQUEST:
      return {
        ...state,
        entries: null,
        isFetchingEntries: true,
        fetchingEntriesFailed: false,
      };
    case FETCH_ENTRIES_RESPONSE:
      return {
        ...state,
        entries: action.payload || [],
        isFetchingEntries: false,
        fetchingEntriesFailed: false,
      };
    case FETCH_ENTRIES_FAILURE:
      return {
        ...state,
        entries: null,
        isFetchingEntries: false,
        fetchingEntriesFailed: true,
      };
    case FETCH_ENTRY_REQUEST:
      return {
        ...state,
        entries: null,
        isFetchingEntries: true,
        fetchingEntriesFailed: false,
      };
    case FETCH_ENTRY_RESPONSE:
      return {
        ...state,
        activeEntry: action.payload,
        isFetchingEntries: false,
        fetchingEntriesFailed: false,
      };
    case FETCH_ENTRY_MATCHES_RESPONSE:
      return {
        ...state,
        matches: action.payload,
      };
    case FETCH_ENTRY_FAILURE:
      return {
        ...state,
        entries: null,
        isFetchingEntries: false,
        fetchingEntriesFailed: true,
      };
    case POST_ENTRY_REQUEST:
      return {
        ...state,
        isPostingEntry: true,
        postingEntryError: null,
        postingEntryValidationErrors: null,
        postingEntryFailed: false,
      };
    case POST_ENTRY_RESPONSE:
      return {
        ...state,
        isPostingEntry: false,
        postingEntryError: null,
        postingEntryValidationErrors: null,
        postingEntryFailed: false,
      };
    case POST_ENTRY_FAILURE:
      return {
        ...state,
        isPostingEntry: false,
        postingEntryError: action.payload.error || 'An error occurred',
        postingEntryValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingEntryFailed: true,
      };
    default:
      return state;
  }
};

export default entriesReducer;
