import React from 'react';
import './ButtonDuo.scss';
import 'bootstrap/dist/css/bootstrap.min.css';


type Props = {
  title1: string;
  title2: string;
  onClick?: () => void;
  grid?: boolean;
};

const ButtonDuo = (props: Props) => {
  const {
    title1 = '',
    title2 = '',
    grid,
    onClick,


  } = props;


  return (
        <div className="buttonDuo" onClick={onClick}>
            <div className={grid ? 'left-red' : 'left'}>
                <span>{title1}</span>
            </div>
            <div className={grid ? 'right' : 'right-red'}>
                <div>
                    <span>{title2}</span>
                </div>
            </div>
        </div>

  );
};

export default ButtonDuo;
