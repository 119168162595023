import {
  FETCH_FIELD_TYPE_OPTION_FAILURE,
  FETCH_FIELD_TYPE_OPTION_REQUEST,
  FETCH_FIELD_TYPE_OPTION_RESPONSE,
  FETCH_FIELD_TYPE_OPTIONS_FAILURE,
  FETCH_FIELD_TYPE_OPTIONS_REQUEST,
  FETCH_FIELD_TYPE_OPTIONS_RESPONSE,
  POST_FIELD_TYPE_OPTION_FAILURE,
  POST_FIELD_TYPE_OPTION_RESPONSE,
  FieldTypeOptionActionTypes,
  FieldTypeOptionState,
  POST_FIELD_TYPE_OPTION_REQUEST,
} from '../types/FieldTypeOption';
import { parseValidationErrors } from '../helpers/functions';

const initialState: FieldTypeOptionState = {
  fieldTypeOptions: null,
  activeFieldTypeOption: null,
  isFetchingFieldTypeOptions: false,
  fetchingFieldTypeOptionsFailed: false,
  isPostingFieldTypeOption: false,
  postingFieldTypeOptionFailed: false,
  postingFieldTypeOptionError: null,
  postingFieldTypeOptionValidationErrors: null,
};

const fieldTypeOptionsReducer = (
  state = initialState,
  action: FieldTypeOptionActionTypes,
): FieldTypeOptionState => {
  switch (action.type) {
    case FETCH_FIELD_TYPE_OPTIONS_REQUEST:
      return {
        ...state,
        fieldTypeOptions: null,
        isFetchingFieldTypeOptions: true,
        fetchingFieldTypeOptionsFailed: false,
      };
    case FETCH_FIELD_TYPE_OPTIONS_RESPONSE:
      return {
        ...state,
        fieldTypeOptions: action.payload || [],
        isFetchingFieldTypeOptions: false,
        fetchingFieldTypeOptionsFailed: false,
      };
    case FETCH_FIELD_TYPE_OPTIONS_FAILURE:
      return {
        ...state,
        fieldTypeOptions: null,
        isFetchingFieldTypeOptions: false,
        fetchingFieldTypeOptionsFailed: true,
      };
    case FETCH_FIELD_TYPE_OPTION_REQUEST:
      return {
        ...state,
        fieldTypeOptions: null,
        isFetchingFieldTypeOptions: true,
        fetchingFieldTypeOptionsFailed: false,
      };
    case FETCH_FIELD_TYPE_OPTION_RESPONSE:
      return {
        ...state,
        activeFieldTypeOption: action.payload,
        isFetchingFieldTypeOptions: false,
        fetchingFieldTypeOptionsFailed: false,
      };
    case FETCH_FIELD_TYPE_OPTION_FAILURE:
      return {
        ...state,
        fieldTypeOptions: null,
        isFetchingFieldTypeOptions: false,
        fetchingFieldTypeOptionsFailed: true,
      };
    case POST_FIELD_TYPE_OPTION_REQUEST:
      return {
        ...state,
        isPostingFieldTypeOption: true,
        postingFieldTypeOptionError: null,
        postingFieldTypeOptionValidationErrors: null,
        postingFieldTypeOptionFailed: false,
      };
    case POST_FIELD_TYPE_OPTION_RESPONSE:
      return {
        ...state,
        isPostingFieldTypeOption: false,
        postingFieldTypeOptionError: null,
        postingFieldTypeOptionValidationErrors: null,
        postingFieldTypeOptionFailed: false,
      };
    case POST_FIELD_TYPE_OPTION_FAILURE:
      return {
        ...state,
        isPostingFieldTypeOption: false,
        postingFieldTypeOptionError: action.payload.error || 'An error occurred',
        postingFieldTypeOptionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingFieldTypeOptionFailed: true,
      };
    default:
      return state;
  }
};

export default fieldTypeOptionsReducer;
