import {
  FETCH_ENTRY_FIELD_HISTORY_FAILURE,
  FETCH_ENTRY_FIELD_HISTORY_REQUEST,
  FETCH_ENTRY_FIELD_HISTORY_RESPONSE,
  FETCH_ENTRY_FIELDS_HISTORY_FAILURE,
  FETCH_ENTRY_FIELDS_HISTORY_REQUEST,
  FETCH_ENTRY_FIELDS_HISTORY_RESPONSE,
  /*  POST_ENTRY_FIELD_HISTORY_FAILURE,
  POST_ENTRY_FIELD_HISTORY_RESPONSE,*/
  EntryFieldHistoryActionTypes,
  EntryFieldHistoryState,
/*  POST_ENTRY_FIELD_HISTORY_REQUEST,*/
} from '../types/EntryFieldHistory';
/*import { parseValidationErrors } from '../helpers/functions';*/

const initialState: EntryFieldHistoryState = {
  entryFieldsHistory: null,
  activeEntryFieldHistory: null,
  isFetchingEntryFieldsHistory: false,
  fetchingEntryFieldsHistoryFailed: false,
  isPostingEntryFieldHistory: false,
  postingEntryFieldHistoryFailed: false,
  postingEntryFieldHistoryError: null,
  postingEntryFieldHistoryValidationErrors: null,
};

const entryFieldsHistoryReducer = (
  state = initialState,
  action: EntryFieldHistoryActionTypes,
): EntryFieldHistoryState => {
  switch (action.type) {
    case FETCH_ENTRY_FIELDS_HISTORY_REQUEST:
      return {
        ...state,
        entryFieldsHistory: null,
        isFetchingEntryFieldsHistory: true,
        fetchingEntryFieldsHistoryFailed: false,
      };
    case FETCH_ENTRY_FIELDS_HISTORY_RESPONSE:
      return {
        ...state,
        entryFieldsHistory: action.payload || [],
        isFetchingEntryFieldsHistory: false,
        fetchingEntryFieldsHistoryFailed: false,
      };
    case FETCH_ENTRY_FIELDS_HISTORY_FAILURE:
      return {
        ...state,
        entryFieldsHistory: null,
        isFetchingEntryFieldsHistory: false,
        fetchingEntryFieldsHistoryFailed: true,
      };
    case FETCH_ENTRY_FIELD_HISTORY_REQUEST:
      return {
        ...state,
        entryFieldsHistory: null,
        isFetchingEntryFieldsHistory: true,
        fetchingEntryFieldsHistoryFailed: false,
      };
    case FETCH_ENTRY_FIELD_HISTORY_RESPONSE:
      return {
        ...state,
        activeEntryFieldHistory: action.payload,
        isFetchingEntryFieldsHistory: false,
        fetchingEntryFieldsHistoryFailed: false,
      };
    case FETCH_ENTRY_FIELD_HISTORY_FAILURE:
      return {
        ...state,
        entryFieldsHistory: null,
        isFetchingEntryFieldsHistory: false,
        fetchingEntryFieldsHistoryFailed: true,
      };
      /*    case POST_ENTRY_FIELD_HISTORY_REQUEST:
      return {
        ...state,
        isPostingEntryFieldHistory: true,
        postingEntryFieldHistoryError: null,
        postingEntryFieldHistoryValidationErrors: null,
        postingEntryFieldHistoryFailed: false,
      };
    case POST_ENTRY_FIELD_HISTORY_RESPONSE:
      return {
        ...state,
        isPostingEntryFieldHistory: false,
        postingEntryFieldHistoryError: null,
        postingEntryFieldHistoryValidationErrors: null,
        postingEntryFieldHistoryFailed: false,
      };
    case POST_ENTRY_FIELD_HISTORY_FAILURE:
      return {
        ...state,
        isPostingEntryFieldHistory: false,
        postingEntryFieldHistoryError: action.payload.error || 'An error occurred',
        postingEntryFieldHistoryValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingEntryFieldHistoryFailed: true,
      };*/
    default:
      return state;
  }
};

export default entryFieldsHistoryReducer;
