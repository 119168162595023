import { ApiDataModel, FormError } from './Common';

export const FETCH_SOLUTION_INPUT_TAGS_REQUEST = 'FETCH_SOLUTION_INPUT_TAGS_REQUEST';
export const FETCH_SOLUTION_INPUT_TAGS_RESPONSE = 'FETCH_SOLUTION_INPUT_TAGS_RESPONSE';
export const FETCH_SOLUTION_INPUT_TAGS_FAILURE = 'FETCH_SOLUTION_INPUT_TAGS_FAILURE';

export const FETCH_SOLUTION_INPUT_TAG_REQUEST = 'FETCH_SOLUTION_INPUT_TAG_REQUEST';
export const FETCH_SOLUTION_INPUT_TAG_RESPONSE = 'FETCH_SOLUTION_INPUT_TAG_RESPONSE';
export const FETCH_SOLUTION_INPUT_TAG_FAILURE = 'FETCH_SOLUTION_INPUT_TAG_FAILURE';

export const POST_SOLUTION_INPUT_TAG_REQUEST = 'POST_SOLUTION_INPUT_TAG_REQUEST';
export const POST_SOLUTION_INPUT_TAG_RESPONSE = 'POST_SOLUTION_INPUT_TAG_RESPONSE';
export const POST_SOLUTION_INPUT_TAG_FAILURE = 'POST_SOLUTION_INPUT_TAG_FAILURE';

export interface SolutionInputTag {
  id: string;
  solutionId: string;
  tagId: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface SolutionInputTagDataModel extends Omit<ApiDataModel, 'data'> {
  data: SolutionInputTag[];
}

export interface SolutionInputTagState {
  solutionInputTags: SolutionInputTagDataModel | null,
  activeSolutionInputTag: SolutionInputTag | null,
  isFetchingSolutionInputTags: boolean;
  fetchingSolutionInputTagsFailed: boolean;
  postingSolutionInputTagFailed?: boolean;
  isPostingSolutionInputTag: boolean;
  postingSolutionInputTagValidationErrors: FormError[] | null;
  postingSolutionInputTagError: string | null;
}

interface FetchSolutionInputTagsRequestAction {
  type: typeof FETCH_SOLUTION_INPUT_TAGS_REQUEST;
  payload: null;
}

interface FetchSolutionInputTagsResponseAction {
  type: typeof FETCH_SOLUTION_INPUT_TAGS_RESPONSE;
  payload: SolutionInputTagDataModel;
}

interface FetchSolutionInputTagsFailureAction {
  type: typeof FETCH_SOLUTION_INPUT_TAGS_FAILURE;
  payload: null;
}

interface FetchSolutionInputTagRequestAction {
  type: typeof FETCH_SOLUTION_INPUT_TAG_REQUEST;
  payload: null;
}

interface FetchSolutionInputTagResponseAction {
  type: typeof FETCH_SOLUTION_INPUT_TAG_RESPONSE;
  payload: SolutionInputTag;
}

interface FetchSolutionInputTagFailureAction {
  type: typeof FETCH_SOLUTION_INPUT_TAG_FAILURE;
  payload: null;
}

interface PostSolutionInputTagRequestAction {
  type: typeof POST_SOLUTION_INPUT_TAG_REQUEST;
  payload: null;
}

interface PostSolutionInputTagResponseAction {
  type: typeof POST_SOLUTION_INPUT_TAG_RESPONSE;
  payload: null;
}

interface PostSolutionInputTagFailureAction {
  type: typeof POST_SOLUTION_INPUT_TAG_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type SolutionInputTagActionTypes =
    | FetchSolutionInputTagsRequestAction
    | FetchSolutionInputTagsResponseAction
    | FetchSolutionInputTagsFailureAction
    | PostSolutionInputTagRequestAction
    | PostSolutionInputTagResponseAction
    | PostSolutionInputTagFailureAction
    | FetchSolutionInputTagRequestAction
    | FetchSolutionInputTagResponseAction
    | FetchSolutionInputTagFailureAction;
