import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CONFIGURATIONS_REQUEST,
  FETCH_CONFIGURATIONS_RESPONSE,
  FETCH_CONFIGURATIONS_FAILURE,
  FETCH_CONFIGURATION_REQUEST,
  FETCH_CONFIGURATION_RESPONSE,
  FETCH_CONFIGURATION_FAILURE,
  POST_CONFIGURATION_FAILURE,
  POST_CONFIGURATION_REQUEST,
  POST_CONFIGURATION_RESPONSE,
  ConfigurationActionTypes, ConfigurationDataModel, Configuration,
} from '../types/Configuration';

import { RootState } from '../store/configureStore';

import {
  getConfiguration,
  getConfigurations,
  postConfiguration,
  putConfiguration,
  archiveConfiguration,
  getConfigurationByShortcodes,
  getConfigurationByLogin, getDefaultConfiguration,
} from '../api/Configuration';


export function fetchConfigurationsRequest(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_REQUEST,
    payload: null,
  };
}

export function fetchConfigurationsResponse(
  configurations: ConfigurationDataModel,
): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_RESPONSE,
    payload: configurations,
  };
}

export function fetchConfigurationsFailure(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATIONS_FAILURE,
    payload: null,
  };
}

export function fetchConfigurationRequest(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATION_REQUEST,
    payload: null,
  };
}

export function fetchConfigurationResponse(
  configuration: Configuration,
): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATION_RESPONSE,
    payload: configuration,
  };
}

export function fetchConfigurationFailure(): ConfigurationActionTypes {
  return {
    type: FETCH_CONFIGURATION_FAILURE,
    payload: null,
  };
}

export const fetchActiveConfiguration = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getConfiguration(id);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export const fetchDefaultConfiguration = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getDefaultConfiguration();
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export const fetchConfigurationByShortcode = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getConfigurationByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export const fetchConfigurationByLogin = (roleId: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    const asyncResp: any = await getConfigurationByLogin(roleId);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationResponse(asyncResp.data));
    } else {
      await dispatch(fetchConfigurationFailure());
    }
  };

export function postConfigurationRequest(): ConfigurationActionTypes {
  return {
    type: POST_CONFIGURATION_REQUEST,
    payload: null,
  };
}

export function postConfigurationResponse(): ConfigurationActionTypes {
  return {
    type: POST_CONFIGURATION_RESPONSE,
    payload: null,
  };
}

export function postConfigurationFailure(error: string, validationErrors: any): ConfigurationActionTypes {
  return {
    type: POST_CONFIGURATION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchConfigurations = (searchField = '', searchWord = '', colKey = '', sortDirection = '',  paging = '', offset = 1):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchConfigurationsRequest());
    const asyncResp: any = await getConfigurations(searchField, searchWord, colKey, sortDirection, paging, offset);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurationsResponse(asyncResp));
    } else {
      await dispatch(fetchConfigurationsFailure());
    }
  };

export const storeConfiguration = (id: string, offset: string, paging: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await archiveConfiguration(id);
    if (asyncResp?.success) {
      await dispatch(fetchConfigurations('', '', '', '', paging, parseInt(offset)));
    }
  };


export const createConfiguration = (
  groupId:string, clientId:string, backgroundColor:string,
  foregroundColor: string, primaryActionColor: string, secondaryActionColor: string, successColor: string, warningColor: string,
  dropdownColor: string, primaryTextColor: string, secondaryTextColor: string, primaryLogo: string, secondaryLogo: string, favicon:string,
  defaultRole: string | null, enableAI: boolean, isPremium: boolean, enableQR: boolean, notifyOnNewEntry: boolean, allowAnonymousViewFetch:boolean, allowAnonymousEntryFetch:boolean,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postConfigurationRequest());
    const asyncResp: any = await postConfiguration(groupId, clientId, backgroundColor,
      foregroundColor, primaryActionColor, secondaryActionColor, successColor, warningColor, dropdownColor, primaryTextColor,
      secondaryTextColor, primaryLogo, secondaryLogo, favicon, defaultRole, enableAI, isPremium, enableQR, notifyOnNewEntry, allowAnonymousViewFetch, allowAnonymousEntryFetch, active) ;
    if (asyncResp?.success) {
      await dispatch(postConfigurationResponse());
      await dispatch(fetchConfigurations('', '', '', '', '10', 1));
    } else {
      await dispatch(postConfigurationFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateConfiguration = (
  id:string, groupId:string, clientId:string, backgroundColor:string,
  foregroundColor: string, primaryActionColor: string, secondaryActionColor: string, successColor: string,
  warningColor: string, dropdownColor: string, primaryTextColor: string,
  secondaryTextColor: string, primaryLogo: string, secondaryLogo: string,
  favicon:string, defaultRole: string | null, enableAI: boolean,  isPremium: boolean, enableQR: boolean, notifyOnNewEntry: boolean,
  allowAnonymousViewFetch:boolean, allowAnonymousEntryFetch:boolean, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postConfigurationRequest());
    const asyncResp: any = await putConfiguration(id, groupId, clientId, backgroundColor,
      foregroundColor, primaryActionColor, secondaryActionColor, successColor, warningColor, dropdownColor, primaryTextColor, secondaryTextColor,
      primaryLogo, secondaryLogo, favicon, defaultRole, enableAI, isPremium, enableQR, notifyOnNewEntry, allowAnonymousViewFetch, allowAnonymousEntryFetch, active);
    if (asyncResp?.success) {
      await dispatch(postConfigurationResponse());
      await dispatch(fetchConfigurations('', '', '', '', '10', 1));
    } else {
      await dispatch(postConfigurationFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };