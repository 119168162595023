import {
  FETCH_SOLUTION_OUTPUT_TAG_FAILURE,
  FETCH_SOLUTION_OUTPUT_TAG_REQUEST,
  FETCH_SOLUTION_OUTPUT_TAG_RESPONSE,
  FETCH_SOLUTION_OUTPUT_TAGS_FAILURE,
  FETCH_SOLUTION_OUTPUT_TAGS_REQUEST,
  FETCH_SOLUTION_OUTPUT_TAGS_RESPONSE,
  POST_SOLUTION_OUTPUT_TAG_FAILURE,
  POST_SOLUTION_OUTPUT_TAG_RESPONSE,
  SolutionOutputTagActionTypes,
  SolutionOutputTagState,
  POST_SOLUTION_OUTPUT_TAG_REQUEST,
} from '../types/SolutionOutputTag';
import { parseValidationErrors } from '../helpers/functions';

const initialState: SolutionOutputTagState = {
  solutionOutputTags: null,
  activeSolutionOutputTag: null,
  isFetchingSolutionOutputTags: false,
  fetchingSolutionOutputTagsFailed: false,
  isPostingSolutionOutputTag: false,
  postingSolutionOutputTagFailed: false,
  postingSolutionOutputTagError: null,
  postingSolutionOutputTagValidationErrors: null,
};

const solutionOutputTagsReducer = (
  state = initialState,
  action: SolutionOutputTagActionTypes,
): SolutionOutputTagState => {
  switch (action.type) {
    case FETCH_SOLUTION_OUTPUT_TAGS_REQUEST:
      return {
        ...state,
        solutionOutputTags: null,
        isFetchingSolutionOutputTags: true,
        fetchingSolutionOutputTagsFailed: false,
      };
    case FETCH_SOLUTION_OUTPUT_TAGS_RESPONSE:
      return {
        ...state,
        solutionOutputTags: action.payload || [],
        isFetchingSolutionOutputTags: false,
        fetchingSolutionOutputTagsFailed: false,
      };
    case FETCH_SOLUTION_OUTPUT_TAGS_FAILURE:
      return {
        ...state,
        solutionOutputTags: null,
        isFetchingSolutionOutputTags: false,
        fetchingSolutionOutputTagsFailed: true,
      };
    case FETCH_SOLUTION_OUTPUT_TAG_REQUEST:
      return {
        ...state,
        solutionOutputTags: null,
        isFetchingSolutionOutputTags: true,
        fetchingSolutionOutputTagsFailed: false,
      };
    case FETCH_SOLUTION_OUTPUT_TAG_RESPONSE:
      return {
        ...state,
        activeSolutionOutputTag: action.payload,
        isFetchingSolutionOutputTags: false,
        fetchingSolutionOutputTagsFailed: false,
      };
    case FETCH_SOLUTION_OUTPUT_TAG_FAILURE:
      return {
        ...state,
        solutionOutputTags: null,
        isFetchingSolutionOutputTags: false,
        fetchingSolutionOutputTagsFailed: true,
      };
    case POST_SOLUTION_OUTPUT_TAG_REQUEST:
      return {
        ...state,
        isPostingSolutionOutputTag: true,
        postingSolutionOutputTagError: null,
        postingSolutionOutputTagValidationErrors: null,
        postingSolutionOutputTagFailed: false,
      };
    case POST_SOLUTION_OUTPUT_TAG_RESPONSE:
      return {
        ...state,
        isPostingSolutionOutputTag: false,
        postingSolutionOutputTagError: null,
        postingSolutionOutputTagValidationErrors: null,
        postingSolutionOutputTagFailed: false,
      };
    case POST_SOLUTION_OUTPUT_TAG_FAILURE:
      return {
        ...state,
        isPostingSolutionOutputTag: false,
        postingSolutionOutputTagError: action.payload.error || 'An error occurred',
        postingSolutionOutputTagValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingSolutionOutputTagFailed: true,
      };
    default:
      return state;
  }
};

export default solutionOutputTagsReducer;
