import {
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_RESPONSE,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_RESPONSE,
  POST_CATEGORY_FAILURE,
  POST_CATEGORY_RESPONSE,
  CategoryActionTypes,
  CategoryState,
  POST_CATEGORY_REQUEST,
} from '../types/Category';
import { parseValidationErrors } from '../helpers/functions';

const initialState: CategoryState = {
  categories: null,
  activeCategory: null,
  isFetchingCategories: false,
  fetchingCategoriesFailed: false,
  isPostingCategory: false,
  postingCategoryFailed: false,
  postingCategoryError: null,
  postingCategoryValidationErrors: null,
};

const categoriesReducer = (
  state = initialState,
  action: CategoryActionTypes,
): CategoryState => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: null,
        isFetchingCategories: true,
        fetchingCategoriesFailed: false,
      };
    case FETCH_CATEGORIES_RESPONSE:
      return {
        ...state,
        categories: action.payload || [],
        isFetchingCategories: false,
        fetchingCategoriesFailed: false,
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: null,
        isFetchingCategories: false,
        fetchingCategoriesFailed: true,
      };
    case FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        categories: null,
        isFetchingCategories: true,
        fetchingCategoriesFailed: false,
      };
    case FETCH_CATEGORY_RESPONSE:
      return {
        ...state,
        activeCategory: action.payload,
        isFetchingCategories: false,
        fetchingCategoriesFailed: false,
      };
    case FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        categories: null,
        isFetchingCategories: false,
        fetchingCategoriesFailed: true,
      };
    case POST_CATEGORY_REQUEST:
      return {
        ...state,
        isPostingCategory: true,
        postingCategoryError: null,
        postingCategoryValidationErrors: null,
        postingCategoryFailed: false,
      };
    case POST_CATEGORY_RESPONSE:
      return {
        ...state,
        isPostingCategory: false,
        postingCategoryError: null,
        postingCategoryValidationErrors: null,
        postingCategoryFailed: false,
      };
    case POST_CATEGORY_FAILURE:
      return {
        ...state,
        isPostingCategory: false,
        postingCategoryError: action.payload.error || 'An error occurred',
        postingCategoryValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingCategoryFailed: true,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
