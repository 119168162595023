import {
  FETCH_BADGE_FAILURE,
  FETCH_BADGE_REQUEST,
  FETCH_BADGE_RESPONSE,
  FETCH_BADGES_FAILURE,
  FETCH_BADGES_REQUEST,
  FETCH_BADGES_RESPONSE,
  POST_BADGE_FAILURE,
  POST_BADGE_RESPONSE,
  BadgeActionTypes,
  BadgeState,
  POST_BADGE_REQUEST,
} from '../types/Badge';
import { parseValidationErrors } from '../helpers/functions';

const initialState: BadgeState = {
  badges: null,
  activeBadge: null,
  isFetchingBadges: false,
  fetchingBadgesFailed: false,
  isPostingBadge: false,
  postingBadgeFailed: false,
  postingBadgeError: null,
  postingBadgeValidationErrors: null,
};

const badgesReducer = (
  state = initialState,
  action: BadgeActionTypes,
): BadgeState => {
  switch (action.type) {
    case FETCH_BADGES_REQUEST:
      return {
        ...state,
        badges: null,
        isFetchingBadges: true,
        fetchingBadgesFailed: false,
      };
    case FETCH_BADGES_RESPONSE:
      return {
        ...state,
        badges: action.payload || [],
        isFetchingBadges: false,
        fetchingBadgesFailed: false,
      };
    case FETCH_BADGES_FAILURE:
      return {
        ...state,
        badges: null,
        isFetchingBadges: false,
        fetchingBadgesFailed: true,
      };
    case FETCH_BADGE_REQUEST:
      return {
        ...state,
        badges: null,
        isFetchingBadges: true,
        fetchingBadgesFailed: false,
      };
    case FETCH_BADGE_RESPONSE:
      return {
        ...state,
        activeBadge: action.payload,
        isFetchingBadges: false,
        fetchingBadgesFailed: false,
      };
    case FETCH_BADGE_FAILURE:
      return {
        ...state,
        badges: null,
        isFetchingBadges: false,
        fetchingBadgesFailed: true,
      };
    case POST_BADGE_REQUEST:
      return {
        ...state,
        isPostingBadge: true,
        postingBadgeError: null,
        postingBadgeValidationErrors: null,
        postingBadgeFailed: false,
      };
    case POST_BADGE_RESPONSE:
      return {
        ...state,
        isPostingBadge: false,
        postingBadgeError: null,
        postingBadgeValidationErrors: null,
        postingBadgeFailed: false,
      };
    case POST_BADGE_FAILURE:
      return {
        ...state,
        isPostingBadge: false,
        postingBadgeError: action.payload.error || 'An error occurred',
        postingBadgeValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingBadgeFailed: true,
      };
    default:
      return state;
  }
};

export default badgesReducer;
