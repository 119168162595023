import {
  FETCH_FORM_FIELD_ROLE_FAILURE,
  FETCH_FORM_FIELD_ROLE_REQUEST,
  FETCH_FORM_FIELD_ROLE_RESPONSE,
  FETCH_FORM_FIELD_ROLES_FAILURE,
  FETCH_FORM_FIELD_ROLES_REQUEST,
  FETCH_FORM_FIELD_ROLES_RESPONSE,
  POST_FORM_FIELD_ROLE_FAILURE,
  POST_FORM_FIELD_ROLE_RESPONSE,
  FormFieldRoleActionTypes,
  FormFieldRoleState,
  POST_FORM_FIELD_ROLE_REQUEST,
} from '../types/FormFieldRole';
import { parseValidationErrors } from '../helpers/functions';

const initialState: FormFieldRoleState = {
  formFieldRoles: null,
  activeFormFieldRole: null,
  isFetchingFormFieldRoles: false,
  fetchingFormFieldRolesFailed: false,
  isPostingFormFieldRole: false,
  postingFormFieldRoleFailed: false,
  postingFormFieldRoleError: null,
  postingFormFieldRoleValidationErrors: null,

};

const formFieldRolesReducer = (
  state = initialState,
  action: FormFieldRoleActionTypes,
): FormFieldRoleState => {
  switch (action.type) {
    case FETCH_FORM_FIELD_ROLES_REQUEST:
      return {
        ...state,
        formFieldRoles: null,
        isFetchingFormFieldRoles: true,
        fetchingFormFieldRolesFailed: false,
      };
    case FETCH_FORM_FIELD_ROLES_RESPONSE:
      return {
        ...state,
        formFieldRoles: action.payload || [],
        isFetchingFormFieldRoles: false,
        fetchingFormFieldRolesFailed: false,
      };
    case FETCH_FORM_FIELD_ROLES_FAILURE:
      return {
        ...state,
        formFieldRoles: null,
        isFetchingFormFieldRoles: false,
        fetchingFormFieldRolesFailed: true,
      };
    case FETCH_FORM_FIELD_ROLE_REQUEST:
      return {
        ...state,
        formFieldRoles: null,
        isFetchingFormFieldRoles: true,
        fetchingFormFieldRolesFailed: false,
      };
    case FETCH_FORM_FIELD_ROLE_RESPONSE:
      return {
        ...state,
        activeFormFieldRole: action.payload,
        isFetchingFormFieldRoles: false,
        fetchingFormFieldRolesFailed: false,
      };
    case FETCH_FORM_FIELD_ROLE_FAILURE:
      return {
        ...state,
        formFieldRoles: null,
        isFetchingFormFieldRoles: false,
        fetchingFormFieldRolesFailed: true,
      };
    case POST_FORM_FIELD_ROLE_REQUEST:
      return {
        ...state,
        isPostingFormFieldRole: true,
        postingFormFieldRoleError: null,
        postingFormFieldRoleValidationErrors: null,
        postingFormFieldRoleFailed: false,
      };
    case POST_FORM_FIELD_ROLE_RESPONSE:
      return {
        ...state,
        isPostingFormFieldRole: false,
        postingFormFieldRoleError: null,
        postingFormFieldRoleValidationErrors: null,
        postingFormFieldRoleFailed: false,
      };
    case POST_FORM_FIELD_ROLE_FAILURE:
      return {
        ...state,
        isPostingFormFieldRole: false,
        postingFormFieldRoleError: action.payload.error || 'An error occurred',
        postingFormFieldRoleValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingFormFieldRoleFailed: true,
      };

    default:
      return state;
  }
};

export default formFieldRolesReducer;

