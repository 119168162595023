import { ApiDataModel, FormError } from './Common';

export const FETCH_SOLUTION_OUTPUT_TAGS_REQUEST = 'FETCH_SOLUTION_OUTPUT_TAGS_REQUEST';
export const FETCH_SOLUTION_OUTPUT_TAGS_RESPONSE = 'FETCH_SOLUTION_OUTPUT_TAGS_RESPONSE';
export const FETCH_SOLUTION_OUTPUT_TAGS_FAILURE = 'FETCH_SOLUTION_OUTPUT_TAGS_FAILURE';

export const FETCH_SOLUTION_OUTPUT_TAG_REQUEST = 'FETCH_SOLUTION_OUTPUT_TAG_REQUEST';
export const FETCH_SOLUTION_OUTPUT_TAG_RESPONSE = 'FETCH_SOLUTION_OUTPUT_TAG_RESPONSE';
export const FETCH_SOLUTION_OUTPUT_TAG_FAILURE = 'FETCH_SOLUTION_OUTPUT_TAG_FAILURE';

export const POST_SOLUTION_OUTPUT_TAG_REQUEST = 'POST_SOLUTION_OUTPUT_TAG_REQUEST';
export const POST_SOLUTION_OUTPUT_TAG_RESPONSE = 'POST_SOLUTION_OUTPUT_TAG_RESPONSE';
export const POST_SOLUTION_OUTPUT_TAG_FAILURE = 'POST_SOLUTION_OUTPUT_TAG_FAILURE';

export interface SolutionOutputTag {
  id: string;
  solutionId: string;
  tagId: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface SolutionOutputTagDataModel extends Omit<ApiDataModel, 'data'> {
  data: SolutionOutputTag[];
}

export interface SolutionOutputTagState {
  solutionOutputTags: SolutionOutputTagDataModel | null,
  activeSolutionOutputTag: SolutionOutputTag | null,
  isFetchingSolutionOutputTags: boolean;
  fetchingSolutionOutputTagsFailed: boolean;
  postingSolutionOutputTagFailed?: boolean;
  isPostingSolutionOutputTag: boolean;
  postingSolutionOutputTagValidationErrors: FormError[] | null;
  postingSolutionOutputTagError: string | null;
}

interface FetchSolutionOutputTagsRequestAction {
  type: typeof FETCH_SOLUTION_OUTPUT_TAGS_REQUEST;
  payload: null;
}

interface FetchSolutionOutputTagsResponseAction {
  type: typeof FETCH_SOLUTION_OUTPUT_TAGS_RESPONSE;
  payload: SolutionOutputTagDataModel;
}

interface FetchSolutionOutputTagsFailureAction {
  type: typeof FETCH_SOLUTION_OUTPUT_TAGS_FAILURE;
  payload: null;
}

interface FetchSolutionOutputTagRequestAction {
  type: typeof FETCH_SOLUTION_OUTPUT_TAG_REQUEST;
  payload: null;
}

interface FetchSolutionOutputTagResponseAction {
  type: typeof FETCH_SOLUTION_OUTPUT_TAG_RESPONSE;
  payload: SolutionOutputTag;
}

interface FetchSolutionOutputTagFailureAction {
  type: typeof FETCH_SOLUTION_OUTPUT_TAG_FAILURE;
  payload: null;
}

interface PostSolutionOutputTagRequestAction {
  type: typeof POST_SOLUTION_OUTPUT_TAG_REQUEST;
  payload: null;
}

interface PostSolutionOutputTagResponseAction {
  type: typeof POST_SOLUTION_OUTPUT_TAG_RESPONSE;
  payload: null;
}

interface PostSolutionOutputTagFailureAction {
  type: typeof POST_SOLUTION_OUTPUT_TAG_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type SolutionOutputTagActionTypes =
    | FetchSolutionOutputTagsRequestAction
    | FetchSolutionOutputTagsResponseAction
    | FetchSolutionOutputTagsFailureAction
    | PostSolutionOutputTagRequestAction
    | PostSolutionOutputTagResponseAction
    | PostSolutionOutputTagFailureAction
    | FetchSolutionOutputTagRequestAction
    | FetchSolutionOutputTagResponseAction
    | FetchSolutionOutputTagFailureAction;
