import {
  FETCH_FIELD_PROPERTY_FAILURE,
  FETCH_FIELD_PROPERTY_REQUEST,
  FETCH_FIELD_PROPERTY_RESPONSE,
  FETCH_FIELD_PROPERTIES_FAILURE,
  FETCH_FIELD_PROPERTIES_REQUEST,
  FETCH_FIELD_PROPERTIES_RESPONSE,
  POST_FIELD_PROPERTY_FAILURE,
  POST_FIELD_PROPERTY_RESPONSE,
  FieldPropertyActionTypes,
  FieldPropertyState,
  POST_FIELD_PROPERTY_REQUEST,
} from '../types/FieldProperty';
import { parseValidationErrors } from '../helpers/functions';

const initialState: FieldPropertyState = {
  fieldProperties: null,
  activeFieldProperty: null,
  isFetchingFieldProperties: false,
  fetchingFieldPropertiesFailed: false,
  isPostingFieldProperty: false,
  postingFieldPropertyFailed: false,
  postingFieldPropertyError: null,
  postingFieldPropertyValidationErrors: null,
};

const fieldPropertiesReducer = (
  state = initialState,
  action: FieldPropertyActionTypes,
): FieldPropertyState => {
  switch (action.type) {
    case FETCH_FIELD_PROPERTIES_REQUEST:
      return {
        ...state,
        fieldProperties: null,
        isFetchingFieldProperties: true,
        fetchingFieldPropertiesFailed: false,
      };
    case FETCH_FIELD_PROPERTIES_RESPONSE:
      return {
        ...state,
        fieldProperties: action.payload || [],
        isFetchingFieldProperties: false,
        fetchingFieldPropertiesFailed: false,
      };
    case FETCH_FIELD_PROPERTIES_FAILURE:
      return {
        ...state,
        fieldProperties: null,
        isFetchingFieldProperties: false,
        fetchingFieldPropertiesFailed: true,
      };
    case FETCH_FIELD_PROPERTY_REQUEST:
      return {
        ...state,
        fieldProperties: null,
        isFetchingFieldProperties: true,
        fetchingFieldPropertiesFailed: false,
      };
    case FETCH_FIELD_PROPERTY_RESPONSE:
      return {
        ...state,
        activeFieldProperty: action.payload,
        isFetchingFieldProperties: false,
        fetchingFieldPropertiesFailed: false,
      };
    case FETCH_FIELD_PROPERTY_FAILURE:
      return {
        ...state,
        fieldProperties: null,
        isFetchingFieldProperties: false,
        fetchingFieldPropertiesFailed: true,
      };
    case POST_FIELD_PROPERTY_REQUEST:
      return {
        ...state,
        isPostingFieldProperty: true,
        postingFieldPropertyError: null,
        postingFieldPropertyValidationErrors: null,
        postingFieldPropertyFailed: false,
      };
    case POST_FIELD_PROPERTY_RESPONSE:
      return {
        ...state,
        isPostingFieldProperty: false,
        postingFieldPropertyError: null,
        postingFieldPropertyValidationErrors: null,
        postingFieldPropertyFailed: false,
      };
    case POST_FIELD_PROPERTY_FAILURE:
      return {
        ...state,
        isPostingFieldProperty: false,
        postingFieldPropertyError: action.payload.error || 'An error occurred',
        postingFieldPropertyValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingFieldPropertyFailed: true,
      };
    default:
      return state;
  }
};

export default fieldPropertiesReducer;
