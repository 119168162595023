import { ApiDataModel, FormError } from './Common';

export const FETCH_ENTRY_FIELDS_HISTORY_REQUEST = 'FETCH_ENTRY_FIELDS_HISTORY_REQUEST';
export const FETCH_ENTRY_FIELDS_HISTORY_RESPONSE = 'FETCH_ENTRY_FIELDS_HISTORY_RESPONSE';
export const FETCH_ENTRY_FIELDS_HISTORY_FAILURE = 'FETCH_ENTRY_FIELDS_HISTORY_FAILURE';

export const FETCH_ENTRY_FIELD_HISTORY_REQUEST = 'FETCH_ENTRY_FIELD_HISTORY_REQUEST';
export const FETCH_ENTRY_FIELD_HISTORY_RESPONSE = 'FETCH_ENTRY_FIELD_HISTORY_RESPONSE';
export const FETCH_ENTRY_FIELD_HISTORY_FAILURE = 'FETCH_ENTRY_FIELD_HISTORY_FAILURE';

export const POST_ENTRY_FIELD_HISTORY_REQUEST = 'POST_ENTRY_FIELD_HISTORY_REQUEST';
export const POST_ENTRY_FIELD_HISTORY_RESPONSE = 'POST_ENTRY_FIELD_HISTORY_RESPONSE';
export const POST_ENTRY_FIELD_HISTORY_FAILURE = 'POST_ENTRY_FIELD_HISTORY_FAILURE';

export interface EntryFieldHistory {
  id: string;
  groupId: string;
  clientId: string;
  entryId: string;
  entryTitle: string;
  fieldId: string;
  fieldTitle: string;
  fieldType: string;
  value: string;
  notes:string;
  created: Date;
  createdBy: Date;
  updated: Date;
}

export interface EntryFieldHistoryDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryFieldHistory[];
}

export interface EntryFieldHistoryState {
  entryFieldsHistory: EntryFieldHistoryDataModel | null,
  activeEntryFieldHistory: EntryFieldHistory | null,
  isFetchingEntryFieldsHistory: boolean;
  fetchingEntryFieldsHistoryFailed: boolean;
  postingEntryFieldHistoryFailed?: boolean;
  isPostingEntryFieldHistory: boolean;
  postingEntryFieldHistoryValidationErrors: FormError[] | null;
  postingEntryFieldHistoryError: string | null;
}

interface FetchEntryFieldsHistoryRequestAction {
  type: typeof FETCH_ENTRY_FIELDS_HISTORY_REQUEST;
  payload: null;
}

interface FetchEntryFieldsHistoryResponseAction {
  type: typeof FETCH_ENTRY_FIELDS_HISTORY_RESPONSE;
  payload: EntryFieldHistoryDataModel;
}

interface FetchEntryFieldsHistoryFailureAction {
  type: typeof FETCH_ENTRY_FIELDS_HISTORY_FAILURE;
  payload: null;
}

interface FetchEntryFieldHistoryRequestAction {
  type: typeof FETCH_ENTRY_FIELD_HISTORY_REQUEST;
  payload: null;
}

interface FetchEntryFieldHistoryResponseAction {
  type: typeof FETCH_ENTRY_FIELD_HISTORY_RESPONSE;
  payload: EntryFieldHistory;
}

interface FetchEntryFieldHistoryFailureAction {
  type: typeof FETCH_ENTRY_FIELD_HISTORY_FAILURE;
  payload: null;
}

/*interface PostEntryFieldHistoryRequestAction {
  type: typeof POST_ENTRY_FIELD_HISTORY_REQUEST;
  payload: null;
}

interface PostEntryFieldHistoryResponseAction {
  type: typeof POST_ENTRY_FIELD_HISTORY_RESPONSE;
  payload: null;
}

interface PostEntryFieldHistoryFailureAction {
  type: typeof POST_ENTRY_FIELD_HISTORY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}*/

export type EntryFieldHistoryActionTypes =
    | FetchEntryFieldsHistoryRequestAction
    | FetchEntryFieldsHistoryResponseAction
    | FetchEntryFieldsHistoryFailureAction
    /*    | PostEntryFieldHistoryRequestAction
    | PostEntryFieldHistoryResponseAction
    | PostEntryFieldHistoryFailureAction*/
    | FetchEntryFieldHistoryRequestAction
    | FetchEntryFieldHistoryResponseAction
    | FetchEntryFieldHistoryFailureAction;
