import { ApiDataModel, FormError } from './Common';

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_RESPONSE = 'FETCH_TAGS_RESPONSE';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export const FETCH_TAG_REQUEST = 'FETCH_TAG_REQUEST';
export const FETCH_TAG_RESPONSE = 'FETCH_TAG_RESPONSE';
export const FETCH_TAG_FAILURE = 'FETCH_TAG_FAILURE';

export const POST_TAG_REQUEST = 'POST_TAG_REQUEST';
export const POST_TAG_RESPONSE = 'POST_TAG_RESPONSE';
export const POST_TAG_FAILURE = 'POST_TAG_FAILURE';

export interface Tag {
  id: string;
  value: string;
  type: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface TagDataModel extends Omit<ApiDataModel, 'data'> {
  data: Tag[];
}

export interface TagState {
  tags: TagDataModel | null,
  activeTag: Tag | null,
  isFetchingTags: boolean;
  fetchingTagsFailed: boolean;
  postingTagFailed?: boolean;
  isPostingTag: boolean;
  postingTagValidationErrors: FormError[] | null;
  postingTagError: string | null;
}

interface FetchTagsRequestAction {
  type: typeof FETCH_TAGS_REQUEST;
  payload: null;
}

interface FetchTagsResponseAction {
  type: typeof FETCH_TAGS_RESPONSE;
  payload: TagDataModel;
}

interface FetchTagsFailureAction {
  type: typeof FETCH_TAGS_FAILURE;
  payload: null;
}

interface FetchTagRequestAction {
  type: typeof FETCH_TAG_REQUEST;
  payload: null;
}

interface FetchTagResponseAction {
  type: typeof FETCH_TAG_RESPONSE;
  payload: Tag;
}

interface FetchTagFailureAction {
  type: typeof FETCH_TAG_FAILURE;
  payload: null;
}

interface PostTagRequestAction {
  type: typeof POST_TAG_REQUEST;
  payload: null;
}

interface PostTagResponseAction {
  type: typeof POST_TAG_RESPONSE;
  payload: null;
}

interface PostTagFailureAction {
  type: typeof POST_TAG_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type TagActionTypes =
    | FetchTagsRequestAction
    | FetchTagsResponseAction
    | FetchTagsFailureAction
    | PostTagRequestAction
    | PostTagResponseAction
    | PostTagFailureAction
    | FetchTagRequestAction
    | FetchTagResponseAction
    | FetchTagFailureAction;
