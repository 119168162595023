import {
  FETCH_SOLUTION_BADGE_FAILURE,
  FETCH_SOLUTION_BADGE_REQUEST,
  FETCH_SOLUTION_BADGE_RESPONSE,
  FETCH_SOLUTION_BADGES_FAILURE,
  FETCH_SOLUTION_BADGES_REQUEST,
  FETCH_SOLUTION_BADGES_RESPONSE,
  POST_SOLUTION_BADGE_FAILURE,
  POST_SOLUTION_BADGE_RESPONSE,
  SolutionBadgeActionTypes,
  SolutionBadgeState,
  POST_SOLUTION_BADGE_REQUEST,
} from '../types/SolutionBadge';
import { parseValidationErrors } from '../helpers/functions';

const initialState: SolutionBadgeState = {
  solutionBadges: null,
  activeSolutionBadge: null,
  isFetchingSolutionBadges: false,
  fetchingSolutionBadgesFailed: false,
  isPostingSolutionBadge: false,
  postingSolutionBadgeFailed: false,
  postingSolutionBadgeError: null,
  postingSolutionBadgeValidationErrors: null,
};

const solutionBadgesReducer = (
  state = initialState,
  action: SolutionBadgeActionTypes,
): SolutionBadgeState => {
  switch (action.type) {
    case FETCH_SOLUTION_BADGES_REQUEST:
      return {
        ...state,
        solutionBadges: null,
        isFetchingSolutionBadges: true,
        fetchingSolutionBadgesFailed: false,
      };
    case FETCH_SOLUTION_BADGES_RESPONSE:
      return {
        ...state,
        solutionBadges: action.payload || [],
        isFetchingSolutionBadges: false,
        fetchingSolutionBadgesFailed: false,
      };
    case FETCH_SOLUTION_BADGES_FAILURE:
      return {
        ...state,
        solutionBadges: null,
        isFetchingSolutionBadges: false,
        fetchingSolutionBadgesFailed: true,
      };
    case FETCH_SOLUTION_BADGE_REQUEST:
      return {
        ...state,
        solutionBadges: null,
        isFetchingSolutionBadges: true,
        fetchingSolutionBadgesFailed: false,
      };
    case FETCH_SOLUTION_BADGE_RESPONSE:
      return {
        ...state,
        activeSolutionBadge: action.payload,
        isFetchingSolutionBadges: false,
        fetchingSolutionBadgesFailed: false,
      };
    case FETCH_SOLUTION_BADGE_FAILURE:
      return {
        ...state,
        solutionBadges: null,
        isFetchingSolutionBadges: false,
        fetchingSolutionBadgesFailed: true,
      };
    case POST_SOLUTION_BADGE_REQUEST:
      return {
        ...state,
        isPostingSolutionBadge: true,
        postingSolutionBadgeError: null,
        postingSolutionBadgeValidationErrors: null,
        postingSolutionBadgeFailed: false,
      };
    case POST_SOLUTION_BADGE_RESPONSE:
      return {
        ...state,
        isPostingSolutionBadge: false,
        postingSolutionBadgeError: null,
        postingSolutionBadgeValidationErrors: null,
        postingSolutionBadgeFailed: false,
      };
    case POST_SOLUTION_BADGE_FAILURE:
      return {
        ...state,
        isPostingSolutionBadge: false,
        postingSolutionBadgeError: action.payload.error || 'An error occurred',
        postingSolutionBadgeValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingSolutionBadgeFailed: true,
      };
    default:
      return state;
  }
};

export default solutionBadgesReducer;
