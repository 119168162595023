import { RootState } from '../store/configureStore';

export const getEntriesHistory = (state: RootState) => state?.entryHistory?.entriesHistory;
export const getEntryTimeline = (state: RootState) => state?.entryHistory?.timeline;

export const getEntryChildLocationHistory = (state: RootState) => state?.entryHistory?.childLocationHistory;
export const getEntryParentLocationHistory = (state: RootState) => state?.entryHistory?.parentLocationHistory;


export const getActiveEntryHistory = (state: RootState) => state?.entryHistory?.activeEntryHistory;

export const isFetchingEntriesHistory = (state: RootState) => state?.entryHistory?.isFetchingEntriesHistory;

export const isPostingEntryHistory = (state: RootState) => state?.entryHistory?.isPostingEntryHistory;
export const postingEntryHistoryFailed = (state: RootState) => state?.entryHistory?.postingEntryHistoryFailed;
export const postingEntryHistoryError = (state: RootState) => state?.entryHistory?.postingEntryHistoryError;
export const postingEntryHistoryValidationErrors = (state: RootState) => state?.entryHistory?.postingEntryHistoryValidationErrors;
export const fetchingEntriesHistoryFailed = (state: RootState) => state?.entryHistory?.fetchingEntriesHistoryFailed;

