import {
  FETCH_VIEW_ROLE_FAILURE,
  FETCH_VIEW_ROLE_REQUEST,
  FETCH_VIEW_ROLE_RESPONSE,
  FETCH_VIEW_ROLES_FAILURE,
  FETCH_VIEW_ROLES_REQUEST,
  FETCH_VIEW_ROLES_RESPONSE,
  POST_VIEW_ROLE_FAILURE,
  POST_VIEW_ROLE_RESPONSE,
  ViewRoleActionTypes,
  ViewRoleState,
  POST_VIEW_ROLE_REQUEST,
} from '../types/ViewRole';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ViewRoleState = {
  viewRoles: null,
  activeViewRole: null,
  isFetchingViewRoles: false,
  fetchingViewRolesFailed: false,
  isPostingViewRole: false,
  postingViewRoleFailed: false,
  postingViewRoleError: null,
  postingViewRoleValidationErrors: null,

};

const viewRolesReducer = (
  state = initialState,
  action: ViewRoleActionTypes,
): ViewRoleState => {
  switch (action.type) {
    case FETCH_VIEW_ROLES_REQUEST:
      return {
        ...state,
        viewRoles: null,
        isFetchingViewRoles: true,
        fetchingViewRolesFailed: false,
      };
    case FETCH_VIEW_ROLES_RESPONSE:
      return {
        ...state,
        viewRoles: action.payload || [],
        isFetchingViewRoles: false,
        fetchingViewRolesFailed: false,
      };
    case FETCH_VIEW_ROLES_FAILURE:
      return {
        ...state,
        viewRoles: null,
        isFetchingViewRoles: false,
        fetchingViewRolesFailed: true,
      };
    case FETCH_VIEW_ROLE_REQUEST:
      return {
        ...state,
        viewRoles: null,
        isFetchingViewRoles: true,
        fetchingViewRolesFailed: false,
      };
    case FETCH_VIEW_ROLE_RESPONSE:
      return {
        ...state,
        activeViewRole: action.payload,
        isFetchingViewRoles: false,
        fetchingViewRolesFailed: false,
      };
    case FETCH_VIEW_ROLE_FAILURE:
      return {
        ...state,
        viewRoles: null,
        isFetchingViewRoles: false,
        fetchingViewRolesFailed: true,
      };
    case POST_VIEW_ROLE_REQUEST:
      return {
        ...state,
        isPostingViewRole: true,
        postingViewRoleError: null,
        postingViewRoleValidationErrors: null,
        postingViewRoleFailed: false,
      };
    case POST_VIEW_ROLE_RESPONSE:
      return {
        ...state,
        isPostingViewRole: false,
        postingViewRoleError: null,
        postingViewRoleValidationErrors: null,
        postingViewRoleFailed: false,
      };
    case POST_VIEW_ROLE_FAILURE:
      return {
        ...state,
        isPostingViewRole: false,
        postingViewRoleError: action.payload.error || 'An error occurred',
        postingViewRoleValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingViewRoleFailed: true,
      };

    default:
      return state;
  }
};

export default viewRolesReducer;

