import {
  FETCH_MATERIALSFUNCTION_FAILURE,
  FETCH_MATERIALSFUNCTION_REQUEST,
  FETCH_MATERIALSFUNCTION_RESPONSE,
  FETCH_MATERIALSFUNCTIONS_FAILURE,
  FETCH_MATERIALSFUNCTIONS_REQUEST,
  FETCH_MATERIALSFUNCTIONS_RESPONSE,
  POST_MATERIALSFUNCTION_FAILURE,
  POST_MATERIALSFUNCTION_RESPONSE,
  MaterialsFunctionActionTypes,
  MaterialsFunctionState,
  POST_MATERIALSFUNCTION_REQUEST,
} from '../types/MaterialsFunction';
import { parseValidationErrors } from '../helpers/functions';

const initialState: MaterialsFunctionState = {
  materialsFunctions: null,
  activeMaterialsFunction: null,
  isFetchingMaterialsFunctions: false,
  fetchingMaterialsFunctionsFailed: false,
  isPostingMaterialsFunction: false,
  postingMaterialsFunctionFailed: false,
  postingMaterialsFunctionError: null,
  postingMaterialsFunctionValidationErrors: null,
};

const materialsFunctionsReducer = (
  state = initialState,
  action: MaterialsFunctionActionTypes,
): MaterialsFunctionState => {
  switch (action.type) {
    case FETCH_MATERIALSFUNCTIONS_REQUEST:
      return {
        ...state,
        materialsFunctions: null,
        isFetchingMaterialsFunctions: true,
        fetchingMaterialsFunctionsFailed: false,
      };
    case FETCH_MATERIALSFUNCTIONS_RESPONSE:
      return {
        ...state,
        materialsFunctions: action.payload || [],
        isFetchingMaterialsFunctions: false,
        fetchingMaterialsFunctionsFailed: false,
      };
    case FETCH_MATERIALSFUNCTIONS_FAILURE:
      return {
        ...state,
        materialsFunctions: null,
        isFetchingMaterialsFunctions: false,
        fetchingMaterialsFunctionsFailed: true,
      };
    case FETCH_MATERIALSFUNCTION_REQUEST:
      return {
        ...state,
        materialsFunctions: null,
        isFetchingMaterialsFunctions: true,
        fetchingMaterialsFunctionsFailed: false,
      };
    case FETCH_MATERIALSFUNCTION_RESPONSE:
      return {
        ...state,
        activeMaterialsFunction: action.payload,
        isFetchingMaterialsFunctions: false,
        fetchingMaterialsFunctionsFailed: false,
      };
    case FETCH_MATERIALSFUNCTION_FAILURE:
      return {
        ...state,
        materialsFunctions: null,
        isFetchingMaterialsFunctions: false,
        fetchingMaterialsFunctionsFailed: true,
      };
    case POST_MATERIALSFUNCTION_REQUEST:
      return {
        ...state,
        isPostingMaterialsFunction: true,
        postingMaterialsFunctionError: null,
        postingMaterialsFunctionValidationErrors: null,
        postingMaterialsFunctionFailed: false,
      };
    case POST_MATERIALSFUNCTION_RESPONSE:
      return {
        ...state,
        isPostingMaterialsFunction: false,
        postingMaterialsFunctionError: null,
        postingMaterialsFunctionValidationErrors: null,
        postingMaterialsFunctionFailed: false,
      };
    case POST_MATERIALSFUNCTION_FAILURE:
      return {
        ...state,
        isPostingMaterialsFunction: false,
        postingMaterialsFunctionError: action.payload.error || 'An error occurred',
        postingMaterialsFunctionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingMaterialsFunctionFailed: true,
      };
    default:
      return state;
  }
};

export default materialsFunctionsReducer;
