import {
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_RESPONSE,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_RESPONSE,
  POST_USER_FAILURE,
  POST_USER_RESPONSE,
  UserActionTypes,
  UserState,
  POST_USER_REQUEST,
} from '../types/User';
import { parseValidationErrors } from '../helpers/functions';

const initialState: UserState = {
  users: null,
  activeUser: null,
  isFetchingUsers: false,
  fetchingUsersFailed: false,
  isPostingUser: false,
  postingUserFailed: false,
  postingUserError: null,
  postingUserValidationErrors: null,
};

const userReducer = (
  state = initialState,
  action: UserActionTypes,
): UserState => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        users: null,
        isFetchingUsers: true,
        fetchingUsersFailed: false,
      };
    case FETCH_USERS_RESPONSE:
      return {
        ...state,
        users: action.payload || [],
        isFetchingUsers: false,
        fetchingUsersFailed: false,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        users: null,
        isFetchingUsers: false,
        fetchingUsersFailed: true,
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        users: null,
        isFetchingUsers: true,
        fetchingUsersFailed: false,
      };
    case FETCH_USER_RESPONSE:
      return {
        ...state,
        activeUser: action.payload,
        isFetchingUsers: false,
        fetchingUsersFailed: false,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        users: null,
        isFetchingUsers: false,
        fetchingUsersFailed: true,
      };
    case POST_USER_REQUEST:
      return {
        ...state,
        isPostingUser: true,
        postingUserError: null,
        postingUserValidationErrors: null,
        postingUserFailed: false,
      };
    case POST_USER_RESPONSE:
      return {
        ...state,
        isPostingUser: false,
        postingUserError: null,
        postingUserValidationErrors: null,
        postingUserFailed: false,
      };
    case POST_USER_FAILURE:
      return {
        ...state,
        isPostingUser: false,
        postingUserError: action.payload.error || 'An error occurred',
        postingUserValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingUserFailed: true,
      };
    default:
      return state;
  }
};

export default userReducer;
