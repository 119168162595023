import { ApiDataModel, FormError } from './Common';

export const FETCH_MATCHES_REQUEST = 'FETCH_MATCHES_REQUEST';
export const FETCH_MATCHES_RESPONSE = 'FETCH_MATCHES_RESPONSE';
export const FETCH_MATCHES_FAILURE = 'FETCH_MATCHES_FAILURE';

export const FETCH_MATCH_REQUEST = 'FETCH_MATCH_REQUEST';
export const FETCH_MATCH_RESPONSE = 'FETCH_MATCH_RESPONSE';
export const FETCH_MATCH_FAILURE = 'FETCH_MATCH_FAILURE';

export const POST_MATCH_REQUEST = 'POST_MATCH_REQUEST';
export const POST_MATCH_RESPONSE = 'POST_MATCH_RESPONSE';
export const POST_MATCH_FAILURE = 'POST_MATCH_FAILURE';

export interface Match {
  id: string;
  groupId: string;
  clientId: string;
  solutionId: string;
  solutionTitle: string;
  solutionDescription: string;
  entryId: string;
  entryTitle: string;
  entryDescription: string;
  status: string;
  impact: string;
  significance: number;
  reuseHierarchy: number;
  environmentalResponse: string;
  financialResponse: string;
  socialResponse: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface MatchDataModel extends Omit<ApiDataModel, 'data'> {
  data: Match[];
}

export interface MatchState {
  matches: MatchDataModel | null,
  activeMatch: Match | null,
  isFetchingMatches: boolean;
  fetchingMatchesFailed: boolean;
  postingMatchFailed?: boolean;
  isPostingMatch: boolean;
  postingMatchValidationErrors: FormError[] | null;
  postingMatchError: string | null;
}

interface FetchMatchesRequestAction {
  type: typeof FETCH_MATCHES_REQUEST;
  payload: null;
}

interface FetchMatchesResponseAction {
  type: typeof FETCH_MATCHES_RESPONSE;
  payload: MatchDataModel;
}

interface FetchMatchesFailureAction {
  type: typeof FETCH_MATCHES_FAILURE;
  payload: null;
}

interface FetchMatchRequestAction {
  type: typeof FETCH_MATCH_REQUEST;
  payload: null;
}

interface FetchMatchResponseAction {
  type: typeof FETCH_MATCH_RESPONSE;
  payload: Match;
}

interface FetchMatchFailureAction {
  type: typeof FETCH_MATCH_FAILURE;
  payload: null;
}

interface PostMatchRequestAction {
  type: typeof POST_MATCH_REQUEST;
  payload: null;
}

interface PostMatchResponseAction {
  type: typeof POST_MATCH_RESPONSE;
  payload: null;
}

interface PostMatchFailureAction {
  type: typeof POST_MATCH_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type MatchActionTypes =
    | FetchMatchesRequestAction
    | FetchMatchesResponseAction
    | FetchMatchesFailureAction
    | PostMatchRequestAction
    | PostMatchResponseAction
    | PostMatchFailureAction
    | FetchMatchRequestAction
    | FetchMatchResponseAction
    | FetchMatchFailureAction;
