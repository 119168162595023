import { ApiDataModel, FormError } from './Common';

export const FETCH_MATERIALSFUNCTIONS_REQUEST = 'FETCH_MATERIALSFUNCTIONS_REQUEST';
export const FETCH_MATERIALSFUNCTIONS_RESPONSE = 'FETCH_MATERIALSFUNCTIONS_RESPONSE';
export const FETCH_MATERIALSFUNCTIONS_FAILURE = 'FETCH_MATERIALSFUNCTIONS_FAILURE';

export const FETCH_MATERIALSFUNCTION_REQUEST = 'FETCH_MATERIALSFUNCTION_REQUEST';
export const FETCH_MATERIALSFUNCTION_RESPONSE = 'FETCH_MATERIALSFUNCTION_RESPONSE';
export const FETCH_MATERIALSFUNCTION_FAILURE = 'FETCH_MATERIALSFUNCTION_FAILURE';

export const POST_MATERIALSFUNCTION_REQUEST = 'POST_MATERIALSFUNCTION_REQUEST';
export const POST_MATERIALSFUNCTION_RESPONSE = 'POST_MATERIALSFUNCTION_RESPONSE';
export const POST_MATERIALSFUNCTION_FAILURE = 'POST_MATERIALSFUNCTION_FAILURE';

export interface MaterialsFunction {
  id: string;
  value: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface MaterialsFunctionDataModel extends Omit<ApiDataModel, 'data'> {
  data: MaterialsFunction[];
}

export interface MaterialsFunctionState {
  materialsFunctions: MaterialsFunctionDataModel | null,
  activeMaterialsFunction: MaterialsFunction | null,
  isFetchingMaterialsFunctions: boolean;
  fetchingMaterialsFunctionsFailed: boolean;
  postingMaterialsFunctionFailed?: boolean;
  isPostingMaterialsFunction: boolean;
  postingMaterialsFunctionValidationErrors: FormError[] | null;
  postingMaterialsFunctionError: string | null;
}

interface FetchMaterialsFunctionsRequestAction {
  type: typeof FETCH_MATERIALSFUNCTIONS_REQUEST;
  payload: null;
}

interface FetchMaterialsFunctionsResponseAction {
  type: typeof FETCH_MATERIALSFUNCTIONS_RESPONSE;
  payload: MaterialsFunctionDataModel;
}

interface FetchMaterialsFunctionsFailureAction {
  type: typeof FETCH_MATERIALSFUNCTIONS_FAILURE;
  payload: null;
}

interface FetchMaterialsFunctionRequestAction {
  type: typeof FETCH_MATERIALSFUNCTION_REQUEST;
  payload: null;
}

interface FetchMaterialsFunctionResponseAction {
  type: typeof FETCH_MATERIALSFUNCTION_RESPONSE;
  payload: MaterialsFunction;
}

interface FetchMaterialsFunctionFailureAction {
  type: typeof FETCH_MATERIALSFUNCTION_FAILURE;
  payload: null;
}

interface PostMaterialsFunctionRequestAction {
  type: typeof POST_MATERIALSFUNCTION_REQUEST;
  payload: null;
}

interface PostMaterialsFunctionResponseAction {
  type: typeof POST_MATERIALSFUNCTION_RESPONSE;
  payload: null;
}

interface PostMaterialsFunctionFailureAction {
  type: typeof POST_MATERIALSFUNCTION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type MaterialsFunctionActionTypes =
    | FetchMaterialsFunctionsRequestAction
    | FetchMaterialsFunctionsResponseAction
    | FetchMaterialsFunctionsFailureAction
    | PostMaterialsFunctionRequestAction
    | PostMaterialsFunctionResponseAction
    | PostMaterialsFunctionFailureAction
    | FetchMaterialsFunctionRequestAction
    | FetchMaterialsFunctionResponseAction
    | FetchMaterialsFunctionFailureAction;
