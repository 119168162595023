import {
  FETCH_FORM_FAILURE,
  FETCH_FORM_REQUEST,
  FETCH_FORM_RESPONSE,
  FETCH_FORMS_FAILURE,
  FETCH_FORMS_REQUEST,
  FETCH_FORMS_RESPONSE,
  POST_FORM_FAILURE,
  POST_FORM_RESPONSE,
  FormActionTypes,
  FormState,
  POST_FORM_REQUEST,
} from '../types/Form';
import { parseValidationErrors } from '../helpers/functions';

const initialState: FormState = {
  forms: null,
  activeForm: null,
  isFetchingForms: false,
  fetchingFormsFailed: false,
  isPostingForm: false,
  postingFormFailed: false,
  postingFormError: null,
  postingFormValidationErrors: null,
};

const formsReducer = (
  state = initialState,
  action: FormActionTypes,
): FormState => {
  switch (action.type) {
    case FETCH_FORMS_REQUEST:
      return {
        ...state,
        forms: null,
        isFetchingForms: true,
        fetchingFormsFailed: false,
      };
    case FETCH_FORMS_RESPONSE:
      return {
        ...state,
        forms: action.payload || [],
        isFetchingForms: false,
        fetchingFormsFailed: false,
      };
    case FETCH_FORMS_FAILURE:
      return {
        ...state,
        forms: null,
        isFetchingForms: false,
        fetchingFormsFailed: true,
      };
    case FETCH_FORM_REQUEST:
      return {
        ...state,
        forms: null,
        isFetchingForms: true,
        fetchingFormsFailed: false,
      };
    case FETCH_FORM_RESPONSE:
      return {
        ...state,
        activeForm: action.payload,
        isFetchingForms: false,
        fetchingFormsFailed: false,
      };
    case FETCH_FORM_FAILURE:
      return {
        ...state,
        forms: null,
        isFetchingForms: false,
        fetchingFormsFailed: true,
      };
    case POST_FORM_REQUEST:
      return {
        ...state,
        isPostingForm: true,
        postingFormError: null,
        postingFormValidationErrors: null,
        postingFormFailed: false,
      };
    case POST_FORM_RESPONSE:
      return {
        ...state,
        isPostingForm: false,
        postingFormError: null,
        postingFormValidationErrors: null,
        postingFormFailed: false,
      };
    case POST_FORM_FAILURE:
      return {
        ...state,
        isPostingForm: false,
        postingFormError: action.payload.error || 'An error occurred',
        postingFormValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingFormFailed: true,
      };
    default:
      return state;
  }
};

export default formsReducer;
