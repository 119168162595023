import React, { useEffect, useState } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker, Polyline } from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import './MaterialMap.scss';
import MarkerSVG from '../../assets/images/svgs/blue-pointer.svg';
import MarkerSVGGray from '../../assets/images/svgs/gray-pointer.svg';
import MarkerSVGChild from '../../assets/images/svgs/child-pointer.svg';
import MarkerSVGGrayChild from '../../assets/images/svgs/child-grey.svg';
import MarkerSVGParent from '../../assets/images/svgs/parent-pointer.svg';
import MarkerSVGGrayParent from '../../assets/images/svgs/parent-grey.svg';


const API_KEY = 'AIzaSyDrEEdOGNTPiR_ZBRsyMGMGPFxx-JF4X74';
Geocode.setApiKey(API_KEY);
Geocode.enableDebug();

type Props = {
  zoom: number;
  lat?: number;
  view?: string;
  lng?: number;
  activeCardLocation?: (geo: any) => void;
  address?: string;
  childrenLocations?: string[];
  editMode?: boolean;
  historyMode?: boolean;
  edgeHistory?: boolean;
  onChange?: (lat: number, lng: number, zoom: number, address: string) => void
  mapList?: { geolocation: string; id: string; title: string }[] | undefined;
  childList?: { geolocation: { value: string }[]; id: string; title: string; }[] | undefined;
  parentList?: { geolocation: { value: string }[]; id: string; title: string; }[] | undefined;
};


const MaterialMap = (props: Props) => {
  const { activeCardLocation } = props;
  const [location, setLocation] = useState('');

  useEffect(() => {
    if (activeCardLocation) {
      activeCardLocation(location);
    }
  }, [location]);

  const {
    lat, lng, zoom, mapList, childList, parentList, view, editMode = false, historyMode = false, edgeHistory = false, onChange = () => {
    }, address = '',
  } = props;


  const onPlaceSelected = (place: any) => {
    onChange(place.geometry.location.lat(), place.geometry.location.lng(), zoom, place.formatted_address);
  };

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    styles: [{
      height: 1000,
      stylers: [{
        saturation: -100,
      }],
      zIndex: 9999,
    },
    view == 'viewRenderer' && {
      featureType: 'poi',
      stylers: [
        { visibility: 'off' },
      ],
    }],
  };

  const AsyncMap = withScriptjs(
    withGoogleMap(
      mapProps => (
              <GoogleMap
                  options={mapOptions}
                  defaultZoom={zoom}
                  defaultCenter={{ lat, lng }}
                  {...mapProps}
              >
                {mapList && historyMode && mapList?.length > 1 && (
                    <Polyline
                        path={mapList.map(e => ({
                          lat: parseFloat(e.geolocation.split('~')[0]),
                          lng: parseFloat(e.geolocation.split('~')[1]),
                        }))}
                        options={{
                          geodesic:true,
                          strokeColor: 'blue',
                          strokeOpacity: 1,
                          strokeWeight: 1,
                          visible: true,
                          icons: [
                            {
                              icon: {
                                path: 'M 0,-2 0,2 -2,0 z',
                                strokeColor: 'blue',
                                strokeWeight: 2,
                                scale: '100%',
                                offset: '0%',
                                rotation: '90',
                              },
                              repeat: '40px',
                            }],
                          zIndex: 0,
                        }}
                    />
                )}
                {mapList?.map((e, i) => (
                    <Marker
                        key={i}
                        title={e.title}
                        icon={MarkerSVGGray}
                        onClick={() => setLocation(e.geolocation)}
                        position={{
                          lat: parseFloat(e.geolocation.split('~')[0]),
                          lng: parseFloat(e.geolocation.split('~')[1]),
                        }}
                    />
                ))}
                {/*render child history*/}
                {childList && historyMode && edgeHistory && childList?.length > 0 && childList?.map((e, i) => (
                    <React.Fragment key={i}>
                    <Polyline
                        path={e.geolocation.map(loc => ({
                          lat: parseFloat(loc.value.split('~')[0]),
                          lng: parseFloat(loc.value.split('~')[1]),
                        }))}
                        options={{
                          geodesic:true,
                          strokeColor: 'green',
                          strokeOpacity: 1,
                          strokeWeight: 1,
                          visible: true,
                          icons: [
                            {
                              icon: {
                                path: 'M 0,-2 0,2 -2,0 z',
                                strokeColor: 'green',
                                strokeWeight: 2,
                                scale: '100%',
                                offset: '0%',
                                rotation: '90',
                              },
                              repeat: '40px',
                            }],
                          zIndex: 0,
                        }}
                    />
                      {e.geolocation.map((loc, y)=>(<Marker
                        key={y}
                        title={e.title}
                        icon={y === e.geolocation.length - 1 ? MarkerSVGChild : MarkerSVGGrayChild}
                        position={{
                          lat: parseFloat(loc.value.split('~')[0]),
                          lng: parseFloat(loc.value.split('~')[1]),
                        }}
                        />
                      ))}
                    </React.Fragment>
                ))}
                {/*render parent history*/}
                {parentList && historyMode && edgeHistory && parentList?.length > 0 && parentList?.map((e, i) => (
                    <React.Fragment key={i}>
                      <Polyline
                          path={e.geolocation.map(loc => ({
                            lat: parseFloat(loc.value.split('~')[0]),
                            lng: parseFloat(loc.value.split('~')[1]),
                          }))}
                          options={{
                            geodesic:true,
                            strokeColor: 'red',
                            strokeOpacity: 1,
                            strokeWeight: 1,
                            visible: true,
                            icons: [
                              {
                                icon: {
                                  path: 'M 0,-2 0,2 -2,0 z',
                                  strokeColor: 'red',
                                  strokeWeight: 2,
                                  scale: '100%',
                                  offset: '0%',
                                  rotation: '90',
                                },
                                repeat: '40px',
                              }],
                            zIndex: 0,
                          }}
                      />
                      {e.geolocation.map((loc, y)=>(<Marker
                              key={y}
                              title={e.title}
                              icon={y === e.geolocation.length - 1 ? MarkerSVGParent : MarkerSVGGrayParent}
                              position={{
                                lat: parseFloat(loc.value.split('~')[0]),
                                lng: parseFloat(loc.value.split('~')[1]),
                              }}
                          />
                      ))}
                    </React.Fragment>
                ))}
                {!(view == 'viewRenderer') && (
                    <Marker
                        title="Current Location"
                        icon={MarkerSVG}
                        zIndex={3000}
                        position={{ lat, lng }}
                    />
                )}

                {editMode && (
                    <Autocomplete
                        style={{
                          width: '100%',
                          height: '40px',
                          paddingLeft: '16px',
                          marginTop: '2px',
                          marginBottom: '500px',
                        }}
                        inputAutocompleteValue={address}
                        componentRestrictions={{ country: 'uk' }}
                        onPlaceSelected={onPlaceSelected}
                        options={{
                          types: ['geocode', 'establishment'],
                        }}
                    />
                )}
              </GoogleMap>
      ),
    ),
  );


  return <>
    {historyMode && <span>Current location:</span>}
        {address && <div>
            <span className="badge rounded-pill bg-primary mb-2">{address}</span>
        </div>}
        <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=Function.prototype`}
            loadingElement={
                <div style={{ height: '100%' }}/>
            }
            containerElement={
                <div style={view == 'viewRenderer' ? { height: 'calc(100vh - 14.188rem)' } : { height: '500px' }}/>
            }
            mapElement={
                <div style={{ height: '100%', borderRadius: 10, boxShadow: '1px 2px 5px grey' }}/>
            }
        />
    </>;
};

export default MaterialMap;

