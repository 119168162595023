import React from 'react';
import { Switch, Route } from 'react-router-dom';
import c from '../../helpers/constants';

// import UserLogin from '../../views/admin/User/UserLogin';
import TimelineWidget from '../../views/widgets/TimelineWidget';
import PleaseScan from '../../views/widgets/PleaseScan';

const Router = () => {
  return (
        <Switch>
            <Route path={c.APP_ROUTES.TIMELINE_ENTRY_RENDERER_SHORTCODE} exact component={TimelineWidget}/>
            <Route path={c.APP_ROUTES.DASHBOARD_SHORTCODE} exact component={TimelineWidget}/>
            <Route path={c.APP_ROUTES.DASHBOARD} exact component={PleaseScan}/>

        </Switch>
  );
};

export default Router;
