import React from 'react';
import './PleaseScan.scss';
import logoImage from '../../assets/images/wear2circleLine.png';

const PleaseScan: React.FC = () => {
  return (
        <div className="please-scan-container">
            <img src={logoImage} alt="Wear2Go Logo" className="logo-image" />
            <div className="scan-text">
                <span className="first-line">WHERE2?</span>
                <span className="second-line">
          Scan a valid Wear2Go QR code to start
          or click <a href="https://www.wear2go-materialsexchange.com/mqUFWBvNwawg/5jDwMAjDudji/timeline/entry/6ca1d06f-afc7-411a-aa73-dbff912e947b">here</a> to see an example.
        </span>
            </div>
        </div>
  );
};

export default PleaseScan;
