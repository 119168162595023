import React, { useEffect, useState } from 'react';
import './Timeline.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEntryChildLocationHistory,
  getEntryParentLocationHistory,
  getEntryTimeline,
} from '../../selectors/EntryHistory';
import {
  fetchChildLocationHistory,
  fetchEntryTimeline,
  fetchEntryTimelineByShortcodes, fetchParentLocationHistory,
} from '../../actions/EntryHistory';
import { BsCircle } from 'react-icons/bs';
import Accordion from '../Accordion';
import { format } from 'date-fns';
import MaterialMap from '../MaterialMap';
import { getEntryFieldsHistory } from '../../selectors/EntryFieldHistory';
import ButtonDuo from '../ButtonDuo';
import { fetchEntryFieldHistory, fetchEntryFieldHistoryByShortcodes } from '../../actions/EntryFieldHistory';

type Props = {
  entryId?: string;
  groupShortCode?: string;
  clientShortCode?: string;
};

const Timeline = (props: Props) => {
  const {
    entryId = '',
    groupShortCode = '',
    clientShortCode = '',
  } = props;
  const dispatch = useDispatch();
  const entryTimeline = useSelector(getEntryTimeline);
  const entryFieldHistory = useSelector(getEntryFieldsHistory);
  const childLocationHistory = useSelector(getEntryChildLocationHistory);
  const parentLocationHistory = useSelector(getEntryParentLocationHistory);
  const [timelineView, setTimelineView] = useState(true);


  useEffect(() => {
    if (entryId && !groupShortCode && !clientShortCode) {
      dispatch(fetchEntryTimeline(entryId));
      dispatch(fetchEntryFieldHistory(entryId));
      dispatch(fetchChildLocationHistory(entryId));
      dispatch(fetchParentLocationHistory(entryId));
    } else {
      dispatch(fetchEntryTimelineByShortcodes(groupShortCode, clientShortCode, entryId));
      dispatch(fetchEntryFieldHistoryByShortcodes(groupShortCode, clientShortCode, entryId));
    }
  }, [entryId]);

  const toggle = () => {
    setTimelineView(!timelineView);
  };

  const renderMapHistory = () => {
    const locationHistoryPreFilter = entryFieldHistory?.data.filter(item => item.fieldType === 'map').sort((a, b) => new Date(a.updated).getTime() - new Date(b.updated).getTime());
    const locationHistoryCreatedSort = locationHistoryPreFilter?.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    const firstFound = locationHistoryCreatedSort?.[0]?.fieldId;
    const locationHistory = locationHistoryPreFilter?.filter(item => item.fieldId === firstFound);
    const mapListItems = locationHistory?.map(e => ({
      'geolocation': e.value,
      'id': e.entryId,
      'title': e.entryTitle,
    }));

    const parts = locationHistory?.map(item => item.value?.split('~'));

    const childListItems = childLocationHistory?.data.map(e => ({
      'geolocation': e.locationHistory,
      'id': e.childId,
      'title': e.childTitle,
    }));

    const parentListItems = parentLocationHistory?.data.map(e => ({
      'geolocation': e.locationHistory,
      'id': e.parentId,
      'title': e.parentTitle,
    }));

    return (<div className="row my-3">
      { parts && parts.length > 0 ?
          <div className="row pb-5">

            <MaterialMap
                address={parts[parts.length - 1][3]}
                lat={parseFloat(parts[parts.length - 1][0])}
                lng={parseFloat(parts[parts.length - 1][1])}
                zoom={1.8}
                mapList={mapListItems}
                //@ts-ignore
                childList={childListItems}
                //@ts-ignore
                parentList={parentListItems}
                historyMode={true}
                edgeHistory={true}
            />
            <div className="pb-3"/>
          </div> :
          <span>No location data found.</span>}
    </div>);
  };

  return (
      <>
        <div className={'button-toggle-timeline'}>
          <ButtonDuo title1={'Timeline'} title2={'Location History'} grid={timelineView} onClick={toggle}/></div>
          { timelineView && entryTimeline?.data.map( (e, i, elements) =>
            (i == 0 ? true : elements[i - 1] && e.created !== elements[i - 1].created) &&
        <div key={i} className="row cont my-2">
          <div className="col-1 ">
              <div className="middle">
                <div className="dot">
              <BsCircle/>
                </div>
              <div className="vl"> </div>
              </div>
          </div>
          <div className="col-10">
              <Accordion title={format(new Date(e.created), 'MMMM d, yyyy, pp').toString()}>
                { entryTimeline?.data.filter(time => time.created === e.created).map( (x) =>
                    <>
                <span><b>Field:</b> {x.title}</span>
                      <div className='changes'>
                <span className="before">- {x.before}</span>
                <span className="after">+ {x.after}</span>
                        {x.notes && <span className="notes">Notes: {x.notes}</span>}
                    </div>
                    </>)}
                  </Accordion>
          </div>
        </div>,
          )}{!timelineView && <>{renderMapHistory() }</>}
        { timelineView && (entryTimeline?.data.length === 0 || !entryTimeline) && <div className={'row my-3'}> <span>No data found.</span> </div>}
      </>

  );
};

export default Timeline;
