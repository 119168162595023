import {
  FETCH_FIELD_TYPE_FAILURE,
  FETCH_FIELD_TYPE_REQUEST,
  FETCH_FIELD_TYPE_RESPONSE,
  FETCH_FIELD_TYPES_FAILURE,
  FETCH_FIELD_TYPES_REQUEST,
  FETCH_FIELD_TYPES_RESPONSE,
  POST_FIELD_TYPE_FAILURE,
  POST_FIELD_TYPE_RESPONSE,
  FieldTypeActionTypes,
  FieldTypeState,
  POST_FIELD_TYPE_REQUEST,
} from '../types/FieldType';
import { parseValidationErrors } from '../helpers/functions';

const initialState: FieldTypeState = {
  fieldTypes: null,
  activeFieldType: null,
  isFetchingFieldTypes: false,
  fetchingFieldTypesFailed: false,
  isPostingFieldType: false,
  postingFieldTypeFailed: false,
  postingFieldTypeError: null,
  postingFieldTypeValidationErrors: null,
};

const fieldTypesReducer = (
  state = initialState,
  action: FieldTypeActionTypes,
): FieldTypeState => {
  switch (action.type) {
    case FETCH_FIELD_TYPES_REQUEST:
      return {
        ...state,
        fieldTypes: null,
        isFetchingFieldTypes: true,
        fetchingFieldTypesFailed: false,
      };
    case FETCH_FIELD_TYPES_RESPONSE:
      return {
        ...state,
        fieldTypes: action.payload || [],
        isFetchingFieldTypes: false,
        fetchingFieldTypesFailed: false,
      };
    case FETCH_FIELD_TYPES_FAILURE:
      return {
        ...state,
        fieldTypes: null,
        isFetchingFieldTypes: false,
        fetchingFieldTypesFailed: true,
      };
    case FETCH_FIELD_TYPE_REQUEST:
      return {
        ...state,
        fieldTypes: null,
        isFetchingFieldTypes: true,
        fetchingFieldTypesFailed: false,
      };
    case FETCH_FIELD_TYPE_RESPONSE:
      return {
        ...state,
        activeFieldType: action.payload,
        isFetchingFieldTypes: false,
        fetchingFieldTypesFailed: false,
      };
    case FETCH_FIELD_TYPE_FAILURE:
      return {
        ...state,
        fieldTypes: null,
        isFetchingFieldTypes: false,
        fetchingFieldTypesFailed: true,
      };
    case POST_FIELD_TYPE_REQUEST:
      return {
        ...state,
        isPostingFieldType: true,
        postingFieldTypeError: null,
        postingFieldTypeValidationErrors: null,
        postingFieldTypeFailed: false,
      };
    case POST_FIELD_TYPE_RESPONSE:
      return {
        ...state,
        isPostingFieldType: false,
        postingFieldTypeError: null,
        postingFieldTypeValidationErrors: null,
        postingFieldTypeFailed: false,
      };
    case POST_FIELD_TYPE_FAILURE:
      return {
        ...state,
        isPostingFieldType: false,
        postingFieldTypeError: action.payload.error || 'An error occurred',
        postingFieldTypeValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingFieldTypeFailed: true,
      };
    default:
      return state;
  }
};

export default fieldTypesReducer;
