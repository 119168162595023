import {
  FETCH_ENTRY_FIELD_FAILURE,
  FETCH_ENTRY_FIELD_REQUEST,
  FETCH_ENTRY_FIELD_RESPONSE,
  FETCH_ENTRY_FIELDS_FAILURE,
  FETCH_ENTRY_FIELDS_REQUEST,
  FETCH_ENTRY_FIELDS_RESPONSE,
  FETCH_ALL_ENTRY_FIELDS_FAILURE,
  FETCH_ALL_ENTRY_FIELDS_REQUEST,
  FETCH_ALL_ENTRY_FIELDS_RESPONSE,
  POST_ENTRY_FIELD_FAILURE,
  POST_ENTRY_FIELD_RESPONSE,
  EntryFieldActionTypes,
  EntryFieldState,
  POST_ENTRY_FIELD_REQUEST,
} from '../types/EntryField';
import { parseValidationErrors } from '../helpers/functions';

const initialState: EntryFieldState = {
  entryFields: null,
  allEntryFields: null,
  activeEntryField: null,
  isFetchingEntryFields: false,
  fetchingEntryFieldsFailed: false,
  isPostingEntryField: false,
  postingEntryFieldFailed: false,
  postingEntryFieldError: null,
  postingEntryFieldValidationErrors: null,
};

const entryFieldsReducer = (
  state = initialState,
  action: EntryFieldActionTypes,
): EntryFieldState => {
  switch (action.type) {
    case FETCH_ENTRY_FIELDS_REQUEST:
      return {
        ...state,
        entryFields: null,
        isFetchingEntryFields: true,
        fetchingEntryFieldsFailed: false,
      };
    case FETCH_ENTRY_FIELDS_RESPONSE:
      return {
        ...state,
        entryFields: action.payload || [],
        isFetchingEntryFields: false,
        fetchingEntryFieldsFailed: false,
      };
    case FETCH_ENTRY_FIELDS_FAILURE:
      return {
        ...state,
        entryFields: null,
        isFetchingEntryFields: false,
        fetchingEntryFieldsFailed: true,
      };
    case FETCH_ALL_ENTRY_FIELDS_REQUEST:
      return {
        ...state,
        allEntryFields: null,
        isFetchingEntryFields: false,
        fetchingEntryFieldsFailed: false,
      };
    case FETCH_ALL_ENTRY_FIELDS_RESPONSE:
      return {
        ...state,
        allEntryFields: action.payload || [],
        isFetchingEntryFields: false,
        fetchingEntryFieldsFailed: false,
      };
    case FETCH_ALL_ENTRY_FIELDS_FAILURE:
      return {
        ...state,
        allEntryFields: null,
        isFetchingEntryFields: false,
        fetchingEntryFieldsFailed: true,
      };
    case FETCH_ENTRY_FIELD_REQUEST:
      return {
        ...state,
        entryFields: null,
        isFetchingEntryFields: true,
        fetchingEntryFieldsFailed: false,
      };
    case FETCH_ENTRY_FIELD_RESPONSE:
      return {
        ...state,
        activeEntryField: action.payload,
        isFetchingEntryFields: false,
        fetchingEntryFieldsFailed: false,
      };
    case FETCH_ENTRY_FIELD_FAILURE:
      return {
        ...state,
        entryFields: null,
        isFetchingEntryFields: false,
        fetchingEntryFieldsFailed: true,
      };
    case POST_ENTRY_FIELD_REQUEST:
      return {
        ...state,
        isPostingEntryField: true,
        postingEntryFieldError: null,
        postingEntryFieldValidationErrors: null,
        postingEntryFieldFailed: false,
      };
    case POST_ENTRY_FIELD_RESPONSE:
      return {
        ...state,
        isPostingEntryField: false,
        postingEntryFieldError: null,
        postingEntryFieldValidationErrors: null,
        postingEntryFieldFailed: false,
      };
    case POST_ENTRY_FIELD_FAILURE:
      return {
        ...state,
        isPostingEntryField: false,
        postingEntryFieldError: action.payload.error || 'An error occurred',
        postingEntryFieldValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingEntryFieldFailed: true,
      };
    default:
      return state;
  }
};

export default entryFieldsReducer;
