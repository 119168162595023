import { ApiDataModel, FormError } from './Common';

export const FETCH_SOLUTIONS_REQUEST = 'FETCH_SOLUTIONS_REQUEST';
export const FETCH_SOLUTIONS_RESPONSE = 'FETCH_SOLUTIONS_RESPONSE';
export const FETCH_SOLUTIONS_FAILURE = 'FETCH_SOLUTIONS_FAILURE';

export const FETCH_SOLUTION_REQUEST = 'FETCH_SOLUTION_REQUEST';
export const FETCH_SOLUTION_RESPONSE = 'FETCH_SOLUTION_RESPONSE';
export const FETCH_SOLUTION_FAILURE = 'FETCH_SOLUTION_FAILURE';

export const POST_SOLUTION_REQUEST = 'POST_SOLUTION_REQUEST';
export const POST_SOLUTION_RESPONSE = 'POST_SOLUTION_RESPONSE';
export const POST_SOLUTION_FAILURE = 'POST_SOLUTION_FAILURE';

export interface Solution {
  id: string;
  title: string;
  description: string;
  location: string;
  contactNumber: string;
  contactEmail: string;
  contactPerson: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface SolutionDataModel extends Omit<ApiDataModel, 'data'> {
  data: Solution[];
}

export interface SolutionState {
  solutions: SolutionDataModel | null,
  activeSolution: Solution | null,
  isFetchingSolutions: boolean;
  fetchingSolutionsFailed: boolean;
  postingSolutionFailed?: boolean;
  isPostingSolution: boolean;
  postingSolutionValidationErrors: FormError[] | null;
  postingSolutionError: string | null;
}

interface FetchSolutionsRequestAction {
  type: typeof FETCH_SOLUTIONS_REQUEST;
  payload: null;
}

interface FetchSolutionsResponseAction {
  type: typeof FETCH_SOLUTIONS_RESPONSE;
  payload: SolutionDataModel;
}

interface FetchSolutionsFailureAction {
  type: typeof FETCH_SOLUTIONS_FAILURE;
  payload: null;
}

interface FetchSolutionRequestAction {
  type: typeof FETCH_SOLUTION_REQUEST;
  payload: null;
}

interface FetchSolutionResponseAction {
  type: typeof FETCH_SOLUTION_RESPONSE;
  payload: Solution;
}

interface FetchSolutionFailureAction {
  type: typeof FETCH_SOLUTION_FAILURE;
  payload: null;
}

interface PostSolutionRequestAction {
  type: typeof POST_SOLUTION_REQUEST;
  payload: null;
}

interface PostSolutionResponseAction {
  type: typeof POST_SOLUTION_RESPONSE;
  payload: null;
}

interface PostSolutionFailureAction {
  type: typeof POST_SOLUTION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type SolutionActionTypes =
    | FetchSolutionsRequestAction
    | FetchSolutionsResponseAction
    | FetchSolutionsFailureAction
    | PostSolutionRequestAction
    | PostSolutionResponseAction
    | PostSolutionFailureAction
    | FetchSolutionRequestAction
    | FetchSolutionResponseAction
    | FetchSolutionFailureAction;
