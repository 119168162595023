import {
  FETCH_SOLUTION_INPUT_TAG_FAILURE,
  FETCH_SOLUTION_INPUT_TAG_REQUEST,
  FETCH_SOLUTION_INPUT_TAG_RESPONSE,
  FETCH_SOLUTION_INPUT_TAGS_FAILURE,
  FETCH_SOLUTION_INPUT_TAGS_REQUEST,
  FETCH_SOLUTION_INPUT_TAGS_RESPONSE,
  POST_SOLUTION_INPUT_TAG_FAILURE,
  POST_SOLUTION_INPUT_TAG_RESPONSE,
  SolutionInputTagActionTypes,
  SolutionInputTagState,
  POST_SOLUTION_INPUT_TAG_REQUEST,
} from '../types/SolutionInputTag';
import { parseValidationErrors } from '../helpers/functions';

const initialState: SolutionInputTagState = {
  solutionInputTags: null,
  activeSolutionInputTag: null,
  isFetchingSolutionInputTags: false,
  fetchingSolutionInputTagsFailed: false,
  isPostingSolutionInputTag: false,
  postingSolutionInputTagFailed: false,
  postingSolutionInputTagError: null,
  postingSolutionInputTagValidationErrors: null,
};

const solutionInputTagsReducer = (
  state = initialState,
  action: SolutionInputTagActionTypes,
): SolutionInputTagState => {
  switch (action.type) {
    case FETCH_SOLUTION_INPUT_TAGS_REQUEST:
      return {
        ...state,
        solutionInputTags: null,
        isFetchingSolutionInputTags: true,
        fetchingSolutionInputTagsFailed: false,
      };
    case FETCH_SOLUTION_INPUT_TAGS_RESPONSE:
      return {
        ...state,
        solutionInputTags: action.payload || [],
        isFetchingSolutionInputTags: false,
        fetchingSolutionInputTagsFailed: false,
      };
    case FETCH_SOLUTION_INPUT_TAGS_FAILURE:
      return {
        ...state,
        solutionInputTags: null,
        isFetchingSolutionInputTags: false,
        fetchingSolutionInputTagsFailed: true,
      };
    case FETCH_SOLUTION_INPUT_TAG_REQUEST:
      return {
        ...state,
        solutionInputTags: null,
        isFetchingSolutionInputTags: true,
        fetchingSolutionInputTagsFailed: false,
      };
    case FETCH_SOLUTION_INPUT_TAG_RESPONSE:
      return {
        ...state,
        activeSolutionInputTag: action.payload,
        isFetchingSolutionInputTags: false,
        fetchingSolutionInputTagsFailed: false,
      };
    case FETCH_SOLUTION_INPUT_TAG_FAILURE:
      return {
        ...state,
        solutionInputTags: null,
        isFetchingSolutionInputTags: false,
        fetchingSolutionInputTagsFailed: true,
      };
    case POST_SOLUTION_INPUT_TAG_REQUEST:
      return {
        ...state,
        isPostingSolutionInputTag: true,
        postingSolutionInputTagError: null,
        postingSolutionInputTagValidationErrors: null,
        postingSolutionInputTagFailed: false,
      };
    case POST_SOLUTION_INPUT_TAG_RESPONSE:
      return {
        ...state,
        isPostingSolutionInputTag: false,
        postingSolutionInputTagError: null,
        postingSolutionInputTagValidationErrors: null,
        postingSolutionInputTagFailed: false,
      };
    case POST_SOLUTION_INPUT_TAG_FAILURE:
      return {
        ...state,
        isPostingSolutionInputTag: false,
        postingSolutionInputTagError: action.payload.error || 'An error occurred',
        postingSolutionInputTagValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingSolutionInputTagFailed: true,
      };
    default:
      return state;
  }
};

export default solutionInputTagsReducer;
