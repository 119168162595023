import {
  FETCH_FIELD_FAILURE,
  FETCH_FIELD_REQUEST,
  FETCH_FIELD_RESPONSE,
  FETCH_FIELDS_FAILURE,
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_RESPONSE,
  POST_FIELD_FAILURE,
  POST_FIELD_RESPONSE,
  FieldActionTypes,
  FieldState,
  POST_FIELD_REQUEST,
} from '../types/Field';
import { parseValidationErrors } from '../helpers/functions';

const initialState: FieldState = {
  fields: null,
  activeField: null,
  isFetchingFields: false,
  fetchingFieldsFailed: false,
  isPostingField: false,
  postingFieldFailed: false,
  postingFieldError: null,
  postingFieldValidationErrors: null,
};

const fieldsReducer = (
  state = initialState,
  action: FieldActionTypes,
): FieldState => {
  switch (action.type) {
    case FETCH_FIELDS_REQUEST:
      return {
        ...state,
        fields: null,
        isFetchingFields: true,
        fetchingFieldsFailed: false,
      };
    case FETCH_FIELDS_RESPONSE:
      return {
        ...state,
        fields: action.payload || [],
        isFetchingFields: false,
        fetchingFieldsFailed: false,
      };
    case FETCH_FIELDS_FAILURE:
      return {
        ...state,
        fields: null,
        isFetchingFields: false,
        fetchingFieldsFailed: true,
      };
    case FETCH_FIELD_REQUEST:
      return {
        ...state,
        fields: null,
        isFetchingFields: true,
        fetchingFieldsFailed: false,
      };
    case FETCH_FIELD_RESPONSE:
      return {
        ...state,
        activeField: action.payload,
        isFetchingFields: false,
        fetchingFieldsFailed: false,
      };
    case FETCH_FIELD_FAILURE:
      return {
        ...state,
        fields: null,
        isFetchingFields: false,
        fetchingFieldsFailed: true,
      };
    case POST_FIELD_REQUEST:
      return {
        ...state,
        isPostingField: true,
        postingFieldError: null,
        postingFieldValidationErrors: null,
        postingFieldFailed: false,
      };
    case POST_FIELD_RESPONSE:
      return {
        ...state,
        isPostingField: false,
        postingFieldError: null,
        postingFieldValidationErrors: null,
        postingFieldFailed: false,
      };
    case POST_FIELD_FAILURE:
      return {
        ...state,
        isPostingField: false,
        postingFieldError: action.payload.error || 'An error occurred',
        postingFieldValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingFieldFailed: true,
      };
    default:
      return state;
  }
};

export default fieldsReducer;
