import {
  FETCH_SOLUTION_FUNCTION_FAILURE,
  FETCH_SOLUTION_FUNCTION_REQUEST,
  FETCH_SOLUTION_FUNCTION_RESPONSE,
  FETCH_SOLUTION_FUNCTIONS_FAILURE,
  FETCH_SOLUTION_FUNCTIONS_REQUEST,
  FETCH_SOLUTION_FUNCTIONS_RESPONSE,
  POST_SOLUTION_FUNCTION_FAILURE,
  POST_SOLUTION_FUNCTION_RESPONSE,
  SolutionFunctionActionTypes,
  SolutionFunctionState,
  POST_SOLUTION_FUNCTION_REQUEST,
} from '../types/SolutionFunction';
import { parseValidationErrors } from '../helpers/functions';

const initialState: SolutionFunctionState = {
  solutionFunctions: null,
  activeSolutionFunction: null,
  isFetchingSolutionFunctions: false,
  fetchingSolutionFunctionsFailed: false,
  isPostingSolutionFunction: false,
  postingSolutionFunctionFailed: false,
  postingSolutionFunctionError: null,
  postingSolutionFunctionValidationErrors: null,
};

const solutionFunctionsReducer = (
  state = initialState,
  action: SolutionFunctionActionTypes,
): SolutionFunctionState => {
  switch (action.type) {
    case FETCH_SOLUTION_FUNCTIONS_REQUEST:
      return {
        ...state,
        solutionFunctions: null,
        isFetchingSolutionFunctions: true,
        fetchingSolutionFunctionsFailed: false,
      };
    case FETCH_SOLUTION_FUNCTIONS_RESPONSE:
      return {
        ...state,
        solutionFunctions: action.payload || [],
        isFetchingSolutionFunctions: false,
        fetchingSolutionFunctionsFailed: false,
      };
    case FETCH_SOLUTION_FUNCTIONS_FAILURE:
      return {
        ...state,
        solutionFunctions: null,
        isFetchingSolutionFunctions: false,
        fetchingSolutionFunctionsFailed: true,
      };
    case FETCH_SOLUTION_FUNCTION_REQUEST:
      return {
        ...state,
        solutionFunctions: null,
        isFetchingSolutionFunctions: true,
        fetchingSolutionFunctionsFailed: false,
      };
    case FETCH_SOLUTION_FUNCTION_RESPONSE:
      return {
        ...state,
        activeSolutionFunction: action.payload,
        isFetchingSolutionFunctions: false,
        fetchingSolutionFunctionsFailed: false,
      };
    case FETCH_SOLUTION_FUNCTION_FAILURE:
      return {
        ...state,
        solutionFunctions: null,
        isFetchingSolutionFunctions: false,
        fetchingSolutionFunctionsFailed: true,
      };
    case POST_SOLUTION_FUNCTION_REQUEST:
      return {
        ...state,
        isPostingSolutionFunction: true,
        postingSolutionFunctionError: null,
        postingSolutionFunctionValidationErrors: null,
        postingSolutionFunctionFailed: false,
      };
    case POST_SOLUTION_FUNCTION_RESPONSE:
      return {
        ...state,
        isPostingSolutionFunction: false,
        postingSolutionFunctionError: null,
        postingSolutionFunctionValidationErrors: null,
        postingSolutionFunctionFailed: false,
      };
    case POST_SOLUTION_FUNCTION_FAILURE:
      return {
        ...state,
        isPostingSolutionFunction: false,
        postingSolutionFunctionError: action.payload.error || 'An error occurred',
        postingSolutionFunctionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingSolutionFunctionFailed: true,
      };
    default:
      return state;
  }
};

export default solutionFunctionsReducer;
