import { ApiDataModel, FormError } from './Common';

export const FETCH_FIELD_TYPE_OPTIONS_REQUEST = 'FETCH_FIELD_TYPE_OPTIONS_REQUEST';
export const FETCH_FIELD_TYPE_OPTIONS_RESPONSE = 'FETCH_FIELD_TYPE_OPTIONS_RESPONSE';
export const FETCH_FIELD_TYPE_OPTIONS_FAILURE = 'FETCH_FIELD_TYPE_OPTIONS_FAILURE';

export const FETCH_FIELD_TYPE_OPTION_REQUEST = 'FETCH_FIELD_TYPE_OPTION_REQUEST';
export const FETCH_FIELD_TYPE_OPTION_RESPONSE = 'FETCH_FIELD_TYPE_OPTION_RESPONSE';
export const FETCH_FIELD_TYPE_OPTION_FAILURE = 'FETCH_FIELD_TYPE_OPTION_FAILURE';

export const POST_FIELD_TYPE_OPTION_REQUEST = 'POST_FIELD_TYPE_OPTION_REQUEST';
export const POST_FIELD_TYPE_OPTION_RESPONSE = 'POST_FIELD_TYPE_OPTION_RESPONSE';
export const POST_FIELD_TYPE_OPTION_FAILURE = 'POST_FIELD_TYPE_OPTION_FAILURE';

export interface FieldTypeOption {
  id: string;
  fieldTypeId: string;
  key: string;
  defaultValue: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface FieldTypeOptionDataModel extends Omit<ApiDataModel, 'data'> {
  data: FieldTypeOption[];
}

export interface FieldTypeOptionState {
  fieldTypeOptions: FieldTypeOptionDataModel | null,
  activeFieldTypeOption: FieldTypeOption | null,
  isFetchingFieldTypeOptions: boolean;
  fetchingFieldTypeOptionsFailed: boolean;
  postingFieldTypeOptionFailed?: boolean;
  isPostingFieldTypeOption: boolean;
  postingFieldTypeOptionValidationErrors: FormError[] | null;
  postingFieldTypeOptionError: string | null;
}

interface FetchFieldTypeOptionsRequestAction {
  type: typeof FETCH_FIELD_TYPE_OPTIONS_REQUEST;
  payload: null;
}

interface FetchFieldTypeOptionsResponseAction {
  type: typeof FETCH_FIELD_TYPE_OPTIONS_RESPONSE;
  payload: FieldTypeOptionDataModel;
}

interface FetchFieldTypeOptionsFailureAction {
  type: typeof FETCH_FIELD_TYPE_OPTIONS_FAILURE;
  payload: null;
}

interface FetchFieldTypeOptionRequestAction {
  type: typeof FETCH_FIELD_TYPE_OPTION_REQUEST;
  payload: null;
}

interface FetchFieldTypeOptionResponseAction {
  type: typeof FETCH_FIELD_TYPE_OPTION_RESPONSE;
  payload: FieldTypeOption;
}

interface FetchFieldTypeOptionFailureAction {
  type: typeof FETCH_FIELD_TYPE_OPTION_FAILURE;
  payload: null;
}

interface PostFieldTypeOptionRequestAction {
  type: typeof POST_FIELD_TYPE_OPTION_REQUEST;
  payload: null;
}

interface PostFieldTypeOptionResponseAction {
  type: typeof POST_FIELD_TYPE_OPTION_RESPONSE;
  payload: null;
}

interface PostFieldTypeOptionFailureAction {
  type: typeof POST_FIELD_TYPE_OPTION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type FieldTypeOptionActionTypes =
    | FetchFieldTypeOptionsRequestAction
    | FetchFieldTypeOptionsResponseAction
    | FetchFieldTypeOptionsFailureAction
    | PostFieldTypeOptionRequestAction
    | PostFieldTypeOptionResponseAction
    | PostFieldTypeOptionFailureAction
    | FetchFieldTypeOptionRequestAction
    | FetchFieldTypeOptionResponseAction
    | FetchFieldTypeOptionFailureAction;
