import { useLocation } from 'react-router-dom';
import axios from 'axios';

import { FormError } from '../types/Common';

const lPrefix = 'MATERIALS_';

export const objectToQueryString: any = (obj: any, prefix: string) => {
  let str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push((v !== null && typeof v === 'object') ?
        objectToQueryString(v, k) :
        encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }
  return str.join('&');
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const localStorageSetItem = (key: string, value: string) => {
  window.localStorage.setItem(`${lPrefix}${key}`, value);
};

export const localStorageGetItem = (key: string) => {
  return window.localStorage.getItem(`${lPrefix}${key}`);
};

export const getToken =  () => {
  return localStorageGetItem('token') || '';
};

export const setToken = (token: string) => {
  localStorageSetItem('token', token);
};

export const getUserToken =  () => {
  return localStorageGetItem('activeUser') || '';
};

export const setUserToken = (user: string) => {
  localStorageSetItem('activeUser', user);
};

// export const setLocalVersion = (manVer: string) => {
//   localStorageSetItem('version', manVer);
// };

export const getLocalVersion = () => {
  return localStorageGetItem('version') || '';
};

// @ts-ignore
export function formatUrl(path, ...vars) {
  const matches = path.match(/(:[a-z])\w+/g);
  let newString = path;
  // @ts-ignore
  matches.forEach((v, i) => {
    if (vars[i]) {
      newString = newString.replace(v, vars[i]);
      newString = newString.replace('?', '');
    } else {
      newString = newString.replace(`/${v}`, '');
      newString = newString.replace('?', '');
    }
  });
  return newString;
}

export function latLngCalculation(mapPrivateItems: { geolocation: string, id: string }[], mapPublicItems: { geolocation: string, id: string }[] ){
  // check if geolocation is a valid float number
  const publicLatList = mapPublicItems?.map( x =>  parseFloat(x?.geolocation.split('~')[0]));
  const privateLatList = mapPrivateItems?.map( x =>  parseFloat(x?.geolocation.split('~')[0]));
  // Map lng calculations
  const publicLngList = mapPublicItems?.map( x =>  parseFloat(x?.geolocation.split('~')[1]));
  const privateLngList = mapPrivateItems?.map( x =>  parseFloat(x?.geolocation.split('~')[1]));
  let  publicLat = 52.1312863;
  let privateLat = 52.1312863;
  let  publicLng = -0.0544277;
  let privateLng = -0.0544277;
  if (publicLatList && !isNaN(publicLatList[0])  && publicLatList.length > 0) {
    publicLat = publicLatList[0];
  }
  if (privateLatList && !isNaN(privateLatList[0])  && privateLatList.length > 0) {
    privateLat = privateLatList[0];
  }
  if (publicLngList && !isNaN(publicLngList[0])  && publicLngList.length > 0) {
    publicLng = publicLngList[0];
  }
  if (privateLngList && !isNaN(privateLngList[0])  && privateLngList.length > 0) {
    privateLng = privateLngList[0];
  }

  return {
    publicLat,
    privateLat, 
    publicLng, 
    privateLng };
}

export function parseValidationErrors(rawErrors: any) {
  if (!rawErrors) {
    return [];
  }
  const errors: FormError[] = [];
  Object.keys(rawErrors).forEach(k => {
    errors.push({
      field: k,
      errors: rawErrors[k],
    });
  });
  return errors;
}

axios.interceptors.request.use((config) => {
  /** In dev, intercepts request and logs it into console for dev */
  // console.info('✉️ ', config);
  const token = getToken();
  // console.log(token);
  // @ts-ignore
  config.headers.Authorization =  `Bearer ${token}`;
  return config;
}, (error) => {
  console.error('✉️ ', error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) { //AKA Unauthorised
    // window.location.href = c.APP_ROUTES.USER_LOGIN;
    // localStorage.setItem('returnUrl', location.pathname);
    // console.error('You are not authorised for this resource. Please login');
  }
  return Promise.reject(error);
});
